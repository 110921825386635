import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction,
  Action
} from 'vuex-module-decorators';
import SensorAPI from '@/store/api/sensor';
import {
  BlockSensorHistory,
  Metstation,
  Sensor,
  SensorLocation,
  SensorLocationHistory
} from '@/store/models/sensor';
import { RawChart } from '@/store/models/charts';

@Module({ name: 'SensorStore', dynamic: true, store })
class SensorStore extends VuexModule {
  Sensors: Sensor[] = [] as Sensor[];
  Metstations: Metstation[] = [] as Metstation[];

  emptySensor: Sensor = {} as Sensor;
  AddEditSensor: Sensor = {} as Sensor;

  SensorLocations: SensorLocation[] = [] as SensorLocation[];
  GeomSensorLocations: SensorLocation[] = [] as SensorLocation[];
  emptySensorLocation: SensorLocation = {} as SensorLocation;
  AddEditSensorLocation: SensorLocation = {} as SensorLocation;

  CurrentBlockSensor: BlockSensorHistory = {} as BlockSensorHistory;
  SensorProfileGraph: RawChart = {} as RawChart;
  MetStationProfileGraph: RawChart = {} as RawChart;

  CurrentSensorLocationSensor: SensorLocationHistory = {} as SensorLocationHistory;

  @MutationAction
  async setAddEditSensor(sensor: Sensor) {
    return { AddEditSensor: sensor };
  }

  @MutationAction
  async getSensors(params?: { block?: number }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
    }
    const sensors = await SensorAPI.getSensors(query);
    return { Sensors: sensors };
  }

  @MutationAction
  async createSensor(sensor: Sensor) {
    const addedSensor = await SensorAPI.createSensor(sensor);
    const sensors = this.Sensors;
    sensors.push(addedSensor);

    return { Sensors: sensors, AddEditSensor: addedSensor };
  }

  @MutationAction
  async updateSensor(sensor: Sensor) {
    const updatedSensor = await SensorAPI.updateSensor(sensor);

    const sensors = this.Sensors;
    const sensorIndex = sensors.findIndex(x => x.id == sensor.id);
    if (sensorIndex != -1) {
      sensors.splice(sensorIndex, 1, updatedSensor);
    }
    return {
      Sensors: sensors,
      AddEditSensor: updatedSensor
    };
  }

  @MutationAction
  async deleteSensor(id: number) {
    await SensorAPI.deleteSensor(id);

    const emptyBlock = this.emptySensor;

    const sensors = this.Sensors;
    if (sensors) {
      const sensorIndex = sensors.findIndex(x => x.id == id);
      if (sensorIndex != -1) {
        sensors.splice(sensorIndex, 1);
      }
    }

    return {
      Sensors: sensors,
      AddEditSensor: JSON.parse(JSON.stringify(emptyBlock))
    };
  }

  @MutationAction
  async setAddEditSensorLocation(sensorLocation: SensorLocation) {
    return { AddEditSensorLocation: sensorLocation };
  }

  @MutationAction
  async getSensorLocations(params?: { block?: number | null; farm?: number }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block_sensor_histories__block=${params.block}`;
      }
      if (params.farm) {
        query += `&block_sensor_histories__block__farm=${params.farm}`;
      }
    }
    const sensorLocations = await SensorAPI.getSensorLocations(query);
    return { SensorLocations: sensorLocations };
  }

  @MutationAction
  async getSensorLocationsWithGeom(params?: {
    block?: number | null;
    farm?: number;
  }) {
    let sensorLocations = this.SensorLocations;
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block_sensor_histories__block=${params.block}`;
      }
      if (params.farm) {
        query += `&block_sensor_histories__block__farm=${params.farm}`;
      }
    }
    if (params && params.farm) {
      sensorLocations = await SensorAPI.getSensorLocationsWithGeom(query);
    }
    return { GeomSensorLocations: sensorLocations };
  }

  @MutationAction
  async getSensorLocationById(id: number) {
    const sensorLocation = await SensorAPI.getSensorLocationById(id);
    return { AddEditSensorLocation: sensorLocation };
  }

  @MutationAction
  async createSensorLocation(sensorLocation: SensorLocation) {
    const addedSensorLocation = await SensorAPI.createSensorLocation(
      sensorLocation
    );
    const sensorLocations = this.SensorLocations;
    sensorLocations.push(addedSensorLocation);

    return {
      SensorLocations: sensorLocations,
      AddEditSensorLocation: addedSensorLocation
    };
  }

  @MutationAction
  async updateSensorLocation(sensorLocation: SensorLocation) {
    const updatedSensorLocation = await SensorAPI.updateSensorLocation(
      sensorLocation
    );

    const sensorLocations = this.SensorLocations;
    const index = sensorLocations.findIndex(x => x.id == sensorLocation.id);
    if (index != -1) {
      sensorLocations.splice(index, 1, updatedSensorLocation);
    }
    return {
      SensorLocations: sensorLocations,
      AddEditSensorLocation: updatedSensorLocation
    };
  }

  @MutationAction
  async deleteSensorLocation(id: number) {
    await SensorAPI.deleteSensorLocation(id);

    const emptySensorLocation = this.emptySensorLocation;

    const sensorLocations = this.SensorLocations;
    if (sensorLocations) {
      const indedx = sensorLocations.findIndex(x => x.id == id);
      if (indedx != -1) {
        sensorLocations.splice(indedx, 1);
      }
    }

    return {
      SensorLocations: sensorLocations,
      AddEditSensorLocation: JSON.parse(JSON.stringify(emptySensorLocation))
    };
  }
  ////////

  @MutationAction
  async setCurrentSensorLocationSensor(
    sensorLocationHistory: SensorLocationHistory
  ) {
    return { CurrentSensorLocationSensor: sensorLocationHistory };
  }

  @MutationAction
  async getSensorLocationHistoryBySensorLocationId(sensorLocationId: number) {
    const blockSensor = await SensorAPI.getSensorLocationHistoryBySensorLocationId(
      sensorLocationId
    );
    return {
      CurrentSensorLocationSensor: blockSensor
    };
  }

  @MutationAction
  async attachSensorLocation(sensorLocationHistory: SensorLocationHistory) {
    const sensor = await SensorAPI.attachSensorLocation(sensorLocationHistory);
    return {
      CurrentSensorLocationSensor: sensor
    };
  }

  @MutationAction
  async detachSensorLocation(sensorLocationHistoryId: number) {
    await SensorAPI.detachSensorLocation(sensorLocationHistoryId);
    return {
      CurrentSensorLocationSensor: {} as SensorLocationHistory
    };
  }

  /////////////////

  @MutationAction
  async setCurrentBlockSensor(sensor: BlockSensorHistory) {
    return { CurrentBlockSensor: sensor };
  }

  @MutationAction
  async getBlockSensorByBlockId(blockId: number) {
    const blockSensor = await SensorAPI.getBlockSensorByBlockId(blockId);
    return {
      CurrentBlockSensor: blockSensor
    };
  }

  @MutationAction
  async attachSensor(blockSensor: BlockSensorHistory) {
    const sensor = await SensorAPI.attachSensor(blockSensor);
    return {
      CurrentBlockSensor: sensor
    };
  }

  @MutationAction
  async detachSensor(blockId: number) {
    await SensorAPI.detachSensor(blockId);
    return {
      CurrentBlockSensor: {} as BlockSensorHistory
    };
  }

  @MutationAction
  async getMetstations() {
    const metstations = await SensorAPI.getMetstations();
    return { Metstations: metstations };
  }

  @Action
  async downloadMetstationHistory(params: {
    stationId: number;
    dateAfter: string;
    dateBefore: string;
  }) {
    const query = `?date_after=${params.dateAfter}&date_before=${params.dateBefore}`;
    return await SensorAPI.downloadMetstationHistory(params.stationId, query);
  }

  @MutationAction
  async getMetStationProfileGraphByMetStationId(params: {
    metStationId: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.startDate) {
        query += `&start_date=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&end_date=${params.endDate}`;
      }
    }
    const metStationProfileGraph = await SensorAPI.getMetStationProfileGraphByMetStationId(
      params.metStationId,
      query
    );
    return { MetStationProfileGraph: metStationProfileGraph };
  }

  @MutationAction
  async getSensorProfileGraphBySensorId(params: {
    sensorId: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.startDate) {
        query += `&start_date=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&end_date=${params.endDate}`;
      }
    }
    const sensorProfileGraph = await SensorAPI.getSensorProfileGraphBySensorId(
      params.sensorId,
      query
    );
    return { SensorProfileGraph: sensorProfileGraph };
  }

  @MutationAction
  async getSensorProfileGraphBySensorLocationId(params: {
    sensorLocationId: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.startDate) {
        query += `&start_date=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&end_date=${params.endDate}`;
      }
    }
    const sensorProfileGraph = await SensorAPI.getSensorProfileGraphBySensorLocationId(
      params.sensorLocationId,
      query
    );
    return { SensorProfileGraph: sensorProfileGraph };
  }
}

export default getModule(SensorStore);
