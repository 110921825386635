import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction
} from 'vuex-module-decorators';
import DiaryAPI from '@/store/api/diary';
import { Diary, AddDiary, PaginatedDiaries } from '@/store/models/diary';
import { QTablePagination } from '@/store/models/meta';

@Module({ name: 'DiaryStore', dynamic: true, store })
class DiaryStore extends VuexModule {
  Diaries: Diary[] = [] as Diary[];
  AddEditDiary: AddDiary = {} as AddDiary;
  emptyDiary: AddDiary = {} as AddDiary;
  PaginatedDiaries: PaginatedDiaries = {} as PaginatedDiaries;

  DiaryTablePagination: QTablePagination = {
    sortBy: 'id',
    descending: true,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  @MutationAction
  async setAddEditDiary(diary: AddDiary) {
    return { AddEditDiary: diary };
  }

  @MutationAction
  async setDiaryTablePagination(data: QTablePagination) {
    return { DiaryTablePagination: data };
  }

  @MutationAction
  async getAllDiary() {
    const diaries = await DiaryAPI.getAllDiary();
    return { Diaries: diaries };
  }

  @MutationAction
  async getPaginatedDiary(params: {
    page: number;
    pageSize: number;
    block?: number;
    farm?: number;
    startDate?: string;
    endDate?: string;
    search?: string;
    ordering?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&id=${params.block}`;
      }
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if (params.pageSize) {
        query += `&page_size=${params.pageSize}`;
      }
      if (params.search) {
        query += `&search=${params.search}`;
      }
      if (params.ordering) {
        query += `&ordering=${params.ordering}`;
      }
      if (params.startDate) {
        query += `&start_date=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&end_date=${params.endDate}`;
      }
    }
    const diaries = await DiaryAPI.getAllDiary(query);
    return { PaginatedDiaries: diaries };
  }

  @MutationAction
  async getDiaryById(id: number) {
    const addedDiary = await DiaryAPI.getDiaryById(id);
    return { AddEditDiary: addedDiary };
  }

  @MutationAction
  async createDiary(diary: FormData) {
    const addedDiary = await DiaryAPI.createDiary(diary);
    const diaries = this.PaginatedDiaries;
    if (diaries.results) {
      diaries.results.push(addedDiary);
    }
    return { PaginatedDiaries: diaries, AddEditDiary: addedDiary };
  }

  @MutationAction
  async updateDiary(params: { id: number; data: FormData }) {
    const updatedDiary = await DiaryAPI.updateDiary(params.id, params.data);

    const blocks = this.PaginatedDiaries;
    if (blocks.results) {
      const blockIndex = blocks.results.findIndex(x => x.id == params.id);
      if (blockIndex != -1) {
        blocks.results.splice(blockIndex, 1, updatedDiary);
      }
    }
    return {
      PaginatedDiaries: blocks,
      AddEditDiary: updatedDiary
    };
  }

  @MutationAction
  async deleteDiary(id: number) {
    await DiaryAPI.deleteDiary(id);

    const emptyDiary = this.emptyDiary;

    const blocks = this.PaginatedDiaries;
    if (blocks) {
      const blockIndex = blocks.results.findIndex(x => x.id == id);
      if (blockIndex != -1) {
        blocks.results.splice(blockIndex, 1);
      }
    }

    return {
      PaginatedDiaries: blocks,
      AddEditDiary: JSON.parse(JSON.stringify(emptyDiary))
    };
  }
}

export default getModule(DiaryStore);
