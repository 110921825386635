import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction
} from 'vuex-module-decorators';
import FruitSizingAPI from '@/store/api/fruitSizing';
import { FruitSizing } from '@/store/models/fruitSizing';
import { RawChart } from '@/store/models/charts';

@Module({ name: 'FruitSizingStore', dynamic: true, store })
class FruitSizingStore extends VuexModule {
  FruitSizings: FruitSizing[] = [] as FruitSizing[];
  FruitSizeDistributionGraph: RawChart = {} as RawChart;
  FruitSizeDistributionGraphOld: RawChart = {} as RawChart;
  RateOfFruitSizeIncreaseGraph: RawChart = {} as RawChart;

  @MutationAction
  async getAllFruitSizing() {
    const fruitSizing = await FruitSizingAPI.getAllFruitSizing();
    return { FruitSizings: fruitSizing };
  }

  @MutationAction
  async getFruitSizeDistributionGraph(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
    }
    const fruitSizingGraph = await FruitSizingAPI.getFruitSizeDistributionGraph(
      query
    );
    return { FruitSizeDistributionGraph: fruitSizingGraph };
  }

  @MutationAction
  async getFruitSizeDistributionGraphOld(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
    }
    const fruitSizingGraph = await FruitSizingAPI.getFruitSizeDistributionGraphOld(
      query
    );
    return { FruitSizeDistributionGraphOld: fruitSizingGraph };
  }

  @MutationAction
  async getRateOfFruitSizeIncreaseGraph(params: {
    block: number;
    startDate?: string;
    endDate?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.block) {
        query += `&block=${params.block}`;
      }
      if (params.startDate) {
        query += `&date_after=${params.startDate}`;
      }
      if (params.endDate) {
        query += `&date_before=${params.endDate}`;
      }
    }
    const fruitSizingGraph = await FruitSizingAPI.getRateOfFruitSizeIncreaseGraph(
      query
    );
    return { RateOfFruitSizeIncreaseGraph: fruitSizingGraph };
  }
}

export default getModule(FruitSizingStore);
