import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction,
  Action
} from 'vuex-module-decorators';
import {
  ChangePassword,
  RegisterUser,
  UserDetail,
  PaginatedUsers,
  Role,
  SimpleUser,
  ChangeRole,
  ValidateInviteToken,
  ValidateInviteTokenResponse,
  PaginatedInvitations,
  DeleteInvitedUser,
  AssignFarm
} from '@/store/models/user';
import { QTablePagination } from '@/store/models/meta';

import UserAPI from '@/store/api/user';
import {
  InviteAdmin,
  InviteConsultant,
  InviteManager,
  InviteMarketer,
  InviteOwner
} from '@/store/models/jwt';

@Module({ name: 'UserStore', dynamic: true, store })
class UserStore extends VuexModule {
  Roles: Role[] = [];
  user: RegisterUser = {} as RegisterUser;
  UserDetail: UserDetail = {} as UserDetail;
  Users: PaginatedUsers = {} as PaginatedUsers;
  SimpleUserList: SimpleUser[] = [] as SimpleUser[];

  UserTablePagination: QTablePagination = {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  PaginatedInvitation: PaginatedInvitations = {} as PaginatedInvitations;
  InvitationTablePagination: QTablePagination = {
    sortBy: 'created_date',
    descending: true,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  @MutationAction
  async setUserTablePagination(data: QTablePagination) {
    return { UserTablePagination: data };
  }

  @MutationAction
  async getUserRoles() {
    const roles = await UserAPI.getUserRoles();
    return { Roles: roles };
  }

  @Action
  async inviteAdmin(inviteEmail: InviteAdmin[]) {
    return await UserAPI.inviteAdmin(inviteEmail);
  }

  @Action
  async enrollAdmin(registerUser: FormData) {
    return await UserAPI.enrollAdmin(registerUser);
  }

  @Action
  async inviteOwner(inviteEmail: InviteOwner[]) {
    return await UserAPI.inviteOwner(inviteEmail);
  }

  @Action
  async enrollOwner(registerUser: FormData) {
    return await UserAPI.enrollOwner(registerUser);
  }

  @Action
  async inviteManager(inviteEmail: InviteManager[]) {
    return await UserAPI.inviteManager(inviteEmail);
  }

  @Action
  async enrollManager(registerUser: FormData) {
    return await UserAPI.enrollManager(registerUser);
  }

  @Action
  async inviteConsultant(inviteEmail: InviteConsultant[]) {
    return await UserAPI.inviteConsultant(inviteEmail);
  }

  @Action
  async enrollConsultant(registerUser: FormData) {
    return await UserAPI.enrollConsultant(registerUser);
  }

  @Action
  async inviteMarketer(inviteEmail: InviteMarketer[]) {
    return await UserAPI.inviteMarketer(inviteEmail);
  }

  @Action
  async enrollMarketer(registerUser: FormData) {
    return await UserAPI.enrollMarketer(registerUser);
  }
  @Action
  async validateInviteToken(
    validateInviteToken: ValidateInviteToken
  ): Promise<ValidateInviteTokenResponse> {
    return await UserAPI.validateInviteToken(validateInviteToken);
  }

  @MutationAction
  async registerUser(registerUser: FormData) {
    const registeredUser = await UserAPI.registerUser(registerUser);
    return { user: registeredUser };
  }

  @Action
  async checkUsername(username: string) {
    return await UserAPI.checkUsername(username);
  }

  @Action
  async changePassword(password: ChangePassword) {
    return await UserAPI.changePassword(password);
  }

  @MutationAction
  async getUserDetailById(id: number) {
    const userDetail = await UserAPI.getUserDetailById(id);
    return { UserDetail: userDetail };
  }

  @MutationAction
  async changeRoleForUser(params: ChangeRole) {
    const userDetail = await UserAPI.changeRoleForUser(params);
    return { UserDetail: userDetail };
  }

  @MutationAction
  async assignFarmsForUser(params: AssignFarm) {
    const userDetail = await UserAPI.assignFarmsForUser(params);
    return { UserDetail: userDetail };
  }

  @MutationAction
  async getAllUsersForAdmin(params?: {
    page?: number;
    search?: string;
    ordering?: string;
    pageSize?: number;
    isActive?: boolean;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if (params.search) {
        query += `&search=${params.search}`;
      }
      if (params.ordering) {
        query += `&ordering=${params.ordering}`;
      }
      if (params.pageSize) {
        query += `&page_size=${params.pageSize}`;
      }
      if (params.isActive != undefined) {
        query += `&is_active=${params.isActive}`;
      }
    }
    const paginatedUsers = await UserAPI.getAllUsersForAdmin(query);
    return { Users: paginatedUsers };
  }

  @MutationAction
  async getSimpleUsersList(params?: {
    role?: string;
    search?: string;
    ordering?: string;
    isActive?: boolean;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.role) {
        query += `&custom_user__role=${params.role}`;
      }
      if (params.search) {
        query += `&search=${params.search}`;
      }
      if (params.ordering) {
        query += `&ordering=${params.ordering}`;
      }
      if (params.isActive != undefined) {
        query += `&is_active=${params.isActive}`;
      }
    }
    const simpleUserList = await UserAPI.getSimpleUserList(query);
    return { SimpleUserList: simpleUserList };
  }

  @MutationAction
  async setInvitationTablePagination(data: QTablePagination) {
    return { InvitationTablePagination: data };
  }
  @MutationAction
  async getInvitations(params: {
    page?: number;
    pageSize?: number;
    search?: string;
    ordering?: string;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if (params.pageSize) {
        query += `&page_size=${params.pageSize}`;
      }
      if (params.search) {
        query += `&search=${params.search}`;
      }
      if (params.ordering) {
        query += `&ordering=${params.ordering}`;
      }
    }
    const invitations = await UserAPI.getInvitations(query);
    return { PaginatedInvitation: invitations };
  }

  @Action
  async cancelInvitationById(id: number) {
    await UserAPI.cancelInvitationById(id);
  }

  @MutationAction
  async deleteInvitedUser(data: DeleteInvitedUser) {
    await UserAPI.deleteInvitedUser(data);
    const invitations = this.PaginatedInvitation;
    if (invitations.results && invitations.results.length > 0) {
      const index = invitations.results.findIndex(x => x.id == data.id);
      invitations.results.splice(index, 1);
    }
    return { PaginatedInvitation: invitations };
  }

  @MutationAction
  async activateUser(userId: number) {
    const userDetail = await UserAPI.activateUser(userId);
    return { UserDetail: userDetail };
  }

  @MutationAction
  async deactivateUser(userId: number) {
    const userDetail = await UserAPI.deactivateUser(userId);
    return { UserDetail: userDetail };
  }
}

export default getModule(UserStore);
