import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction,
  Action
} from 'vuex-module-decorators';
import ConfigurationAPI from '@/store/api/configuration';
import {
  CategoricalEmail,
  DryMatterConfiguration,
  FreeConsultRequest,
  HarvestLoadConfiguration,
  LatestSeasonDate,
  PaginatedSeason,
  Season,
  SeasonCalendar,
  SendUserEmail,
  SMTPSetting
} from '@/store/models/configuration';
import { EmailReset } from '@/store/models/jwt';
import { QTablePagination } from '@/store/models/meta';
import { CommonStore } from '@/store/modules';

@Module({ name: 'ConfigurationStore', dynamic: true, store })
class ConfigurationStore extends VuexModule {
  HarvestLoadConfiguration: HarvestLoadConfiguration = {} as HarvestLoadConfiguration;
  DryMatterConfiguration: DryMatterConfiguration = {} as DryMatterConfiguration;
  SMTPSettings: SMTPSetting = {} as SMTPSetting;
  OldSMTPSettings: SMTPSetting = {} as SMTPSetting;
  PaginatedSeasonCalendar: PaginatedSeason = {} as PaginatedSeason;
  Seasons: Season[] = [] as Season[];
  AddEditSeasonCalendar: SeasonCalendar = {} as SeasonCalendar;
  LatestSeasonDate: LatestSeasonDate = {} as LatestSeasonDate;

  SeasonTablePagination: QTablePagination = {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  @MutationAction
  async setAddEditSeasonCalendar(seasonCalendar: SeasonCalendar) {
    return { AddEditSeasonCalendar: seasonCalendar };
  }

  @MutationAction
  async setSeasonTablePagination(data: QTablePagination) {
    return { SeasonTablePagination: data };
  }

  @MutationAction
  async getHarvestLoadConfigurationByFarmId(id: number) {
    const configuration = await ConfigurationAPI.getHarvestLoadConfiguration(
      id
    );
    return { HarvestLoadConfiguration: configuration };
  }
  @MutationAction
  async updateHarvestLoadConfiguration(
    harvestLoadConfiguration: HarvestLoadConfiguration
  ) {
    const configuration = await ConfigurationAPI.updateHarvestLoadConfiguration(
      harvestLoadConfiguration
    );
    return { HarvestLoadConfiguration: configuration };
  }

  @MutationAction
  async setSMTPSettings(smtpSettings: SMTPSetting) {
    return { SMTPSettings: smtpSettings };
  }

  @MutationAction
  async getSMTPSettings() {
    const smtpSettings = await ConfigurationAPI.getSMTPSetting();
    return {
      SMTPSettings: smtpSettings,
      OldSMTPSettings: JSON.parse(JSON.stringify(smtpSettings))
    };
  }

  @MutationAction
  async updateSMTPSettings(settings: SMTPSetting) {
    const smtpSettings = await ConfigurationAPI.updateSMTPSetting(settings);
    return { SMTPSettings: smtpSettings };
  }

  @Action
  async testSMTPSetting(email: EmailReset) {
    return await ConfigurationAPI.testSMTPSetting(email);
  }

  @MutationAction
  async getSeasonCalendars(params: { farm: number }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farm) {
        query += `farm=${params.farm}`;
      }
    }
    const seasons = await ConfigurationAPI.getSeasonCalendars(query);
    return { PaginatedSeasonCalendar: seasons };
  }

  @MutationAction
  async getSeasonList(params: { farm: number; cropVariety?: number | null }) {
    let query = '';
    if (params) {
      query += '?page_size=0';
      if (params.farm) {
        query += `&season_calendar__farm=${params.farm}`;
      }
      if (params.cropVariety) {
        query += `&season_calendar__crop_variety=${params.cropVariety}`;
      }
    }
    const seasons = await ConfigurationAPI.getSeasons(query);
    return { Seasons: seasons };
  }

  @MutationAction
  async getSeasonCalendarById(id: number) {
    const season = await ConfigurationAPI.getSeasonCalendarById(id);
    return { AddEditSeasonCalendar: season };
  }

  @MutationAction
  async getLatestSeasonDateByFarm(id: number) {
    const latestSeasonDate = await ConfigurationAPI.getLatestSeasonDateByFarm(
      id
    );
    await CommonStore.setStartDate(latestSeasonDate.startDate);
    await CommonStore.setEndDate(latestSeasonDate.endDate);
    return { LatestSeasonDate: latestSeasonDate };
  }

  @MutationAction
  async createSeasonCalendar(data: SeasonCalendar) {
    const season = await ConfigurationAPI.createSeasonCalendar(data);
    return { AddEditSeasonCalendar: season };
  }

  @MutationAction
  async updateSeasonCalendar(data: SeasonCalendar) {
    const season = await ConfigurationAPI.updateSeasonCalendar(data, data.id);
    return { AddEditSeasonCalendar: season };
  }

  @MutationAction
  async deleteSeasonCalendar(id: number) {
    await ConfigurationAPI.deleteSeasonCalendar(id);

    const seasons = this.PaginatedSeasonCalendar;
    if (seasons) {
      const seasonIndex = seasons.results.findIndex(x => x.id == id);
      if (seasonIndex != -1) {
        seasons.results.splice(seasonIndex, 1);
      }
    }
    return {
      PaginatedSeasonCalendar: seasons,
      AddEditSeasonCalendar: {} as SeasonCalendar
    };
  }

  @Action
  async sendCategoricalEmail(categoricalEmail: CategoricalEmail) {
    return await ConfigurationAPI.sendCategoricalEmail(categoricalEmail);
  }

  @Action
  async sendUserEmail(email: SendUserEmail) {
    return await ConfigurationAPI.sendUserEmail(email);
  }

  @Action
  async sendFreeConsultRequest(freeConsultRequest: FreeConsultRequest) {
    return await ConfigurationAPI.sendFreeConsultRequest(freeConsultRequest);
  }

  @MutationAction
  async getDryMatterConfigurationByFarmId(id: number) {
    const configuration = await ConfigurationAPI.getDryMatterConfiguration(id);
    return { DryMatterConfiguration: configuration };
  }

  @MutationAction
  async updateDryMatterConfiguration(
    DryMatterConfiguration: DryMatterConfiguration
  ) {
    const configuration = await ConfigurationAPI.updateDryMatterConfiguration(
      DryMatterConfiguration
    );
    return { DryMatterConfiguration: configuration };
  }
}

export default getModule(ConfigurationStore);
