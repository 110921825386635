import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction,
  Mutation,
  Action
} from 'vuex-module-decorators';

import { Map, View } from 'ol';
import VectorLayer from 'ol/layer/Vector';
import Feature, { FeatureLike } from 'ol/Feature';
import API from '@/store/api/map';
import {
  LayerGroupInterface,
  LayerInterface,
  PanicleCount,
  SliderType
} from '@/store/models/map';
import Axios from 'axios';
import TileWMS from 'ol/source/TileWMS';

import { transform as coTransform } from 'ol/proj';
@Module({ name: 'MapStore', dynamic: true, store })
class MapStore extends VuexModule {
  view: View = new View();
  mapState: Map = new Map({});

  sharedView: View = new View({
    center: coTransform([150.5155619, -23.322713], 'EPSG:4326', 'EPSG:3857'),
    // center: this.transformTo3857([131.171, -12.746]),
    zoom: 15,
    // projection: 'EPSG:4326',
    maxZoom: 21
  });

  updateMapAnimation = 0;
  updateMapAlbums = false;
  selectedFeature: FeatureLike = new Feature();
  selectedPhoto: null | string = null;
  updateFruitLayers = 0;

  geoserverBaseURL = `https://geoserver.fruitmaps.org`;

  panicleCount: PanicleCount = PanicleCount.TOTAL_COUNT;
  // sliderType: SliderType = SliderType.POINT_OF_TIME;
  sliderType: SliderType = SliderType.PERIOD_OF_TIME;

  fruitLayerVisibile = false;

  currentDynamicLayer: {
    current: null | string;
    type: string;
  } = {
    current: null,
    type: 'Flower'
  };

  timerValue = 0;
  timerRangeValue: {
    min: number;
    max: number;
  } = {
    min: 0,
    max: 1
  };

  LoadingBaseLayers = true;
  BaseLayers: LayerInterface[] = [];

  Layers: (LayerInterface | LayerGroupInterface)[] = [];

  treeLegend = '';
  blockLegend = '';

  floweringLegend = '';
  floweringXLegend = '';
  floweringYLegend = '';
  floweringZLegend = '';
  floweringXYLegend = '';
  floweringYZLegend = '';
  floweringPhotoLegend = '';
  fruitCountLegend = '';
  fieldBinsLegend = '';
  fruitSizeLegend = '';
  fruitSizePhotoLegend = '';
  dryMatterLegend = '';
  dryMatterBlockLegend = '';

  vectorLayerSetupComplete = false;

  @MutationAction
  async setMap(map: Map, view?: View) {
    if (!view) {
      view = map.getView();
    }
    return { mapState: map, view: view };
  }

  @Mutation
  async setVectorLayerSetupComplete(value: boolean) {
    this.vectorLayerSetupComplete = value;
  }

  @Mutation
  async setTreeLegend(url: string | undefined) {
    if (url) {
      this.treeLegend = url;
    }
  }

  @Mutation
  async setBlockLegend(url: string | undefined) {
    if (url) {
      this.blockLegend = url;
    }
  }

  @Mutation
  async setFloweringPhotoLegend(url: string | undefined) {
    if (url) {
      this.floweringPhotoLegend = url;
    }
  }

  @Mutation
  async setFloweringLegend(url: string | undefined) {
    if (url) {
      this.floweringLegend = url;
    }
  }

  @Mutation
  async setFloweringXLegend(url: string | undefined) {
    if (url) {
      this.floweringXLegend = url;
    }
  }

  @Mutation
  async setFloweringYLegend(url: string | undefined) {
    if (url) {
      this.floweringYLegend = url;
    }
  }

  @Mutation
  async setFloweringZLegend(url: string | undefined) {
    if (url) {
      this.floweringZLegend = url;
    }
  }

  @Mutation
  async setFloweringXYLegend(url: string | undefined) {
    if (url) {
      this.floweringXYLegend = url;
    }
  }

  @Mutation
  async setFloweringYZLegend(url: string | undefined) {
    if (url) {
      this.floweringYZLegend = url;
    }
  }

  @Mutation
  async setFruitCountLegend(url: string | undefined) {
    if (url) {
      this.fruitCountLegend = url;
    }
  }

  @Mutation
  async setFieldBinsLegend(url: string | undefined) {
    if (url) {
      this.fieldBinsLegend = url;
    }
  }

  @Mutation
  async setFruitSizeLegend(url: string | undefined) {
    if (url) {
      this.fruitSizeLegend = url;
    }
  }

  @Mutation
  async setFruitSizePhotoLegend(url: string | undefined) {
    if (url) {
      this.fruitSizePhotoLegend = url;
    }
  }

  @Mutation
  async setDryMatterLegend(url: string | undefined) {
    if (url) {
      this.dryMatterLegend = url;
    }
  }

  @Mutation
  async setDryMatterBlockLegend(url: string | undefined) {
    if (url) {
      this.dryMatterBlockLegend = url;
    }
  }

  @MutationAction
  async setTimerValue(value: number) {
    return { timerValue: value };
  }

  @MutationAction
  async setTimerRangeValue(value: { min: number; max: number }) {
    return { timerRangeValue: value };
  }

  @MutationAction
  async setFruitLayerVisibile(visible: boolean) {
    return { fruitLayerVisibile: visible };
  }

  @MutationAction
  async setSelectedFeature(feature: FeatureLike) {
    return { selectedFeature: feature };
  }

  @MutationAction
  async setSelectedPhoto(photo: null | string = null) {
    return { selectedPhoto: photo };
  }

  @MutationAction
  async setCurrentDynamicLayer(value: {
    current: null | string;
    type: string;
  }) {
    return { currentDynamicLayer: value };
  }

  @MutationAction
  async setPanicleCount(
    value: 'totalCount' | 'xCountAvg' | 'yCountAvg' | 'zCountAvg'
  ) {
    return { panicleCount: value };
  }

  @MutationAction
  async setSliderType(
    value: SliderType.PERIOD_OF_TIME | SliderType.POINT_OF_TIME
  ) {
    return { sliderType: value };
  }

  @MutationAction
  async setUpdateFruitLayers() {
    return { updateFruitLayers: new Date().getTime() };
  }

  @Action
  async getProxiedFeatureInfo(url: string) {
    return API.proxyView(url);
  }

  @Action
  async getFeatureInfo(url: string) {
    return Axios.get(url);
  }

  @Action
  getLayer(layerID: string) {
    const layers = this.mapState.getLayers().getArray();
    let l = layers[0];
    for (const layer of layers) {
      if (layer.get('id') === layerID) {
        l = layer;
        return l;
      }
    }
    return null;
  }

  @Action
  async getVectorLayer(layerID: string) {
    const layers = (await this.mapState.getLayers().getArray()) as VectorLayer<
      // eslint-disable-next-line
      any
    >[];
    for (const layer of layers) {
      if (layer.get('id') === layerID) {
        return layer;
      }
    }
    return null;
  }

  @Mutation
  setUpdateMapAlbums(value: boolean) {
    this.updateMapAlbums = value;
  }

  @Mutation
  setUpdateMapAnimationId(id: number) {
    this.updateMapAnimation = id;
  }

  @Action
  updateMapSize() {
    this.mapState.updateSize();
    const requestId = requestAnimationFrame(this.updateMapSize);
    this.context.commit('setUpdateMapAnimationId', requestId);
  }

  @Action
  async getBlockFeatureInfo(params: {
    farm: number;
    latitude: number;
    longitude: number;
  }) {
    return await API.getBlockFeature(
      params.farm,
      params.latitude,
      params.longitude
    );
  }

  @Action
  stopUpdateAnimation() {
    cancelAnimationFrame(this.updateMapAnimation);
    this.context.commit('setUpdateMapAnimationId', 0);
  }

  @Action
  setOlControlDarkMode(darkMode: boolean) {
    const olControls = document.querySelectorAll(
      '.ol-control:not(.ol-attribution)'
    );
    // const olControls = document.getElementsByClassName('ol-control');
    if (darkMode) {
      for (const control of olControls) {
        control.classList.add('ol-control--dark');
      }
    } else {
      for (const control of olControls) {
        control.classList.remove('ol-control--dark');
      }
    }
  }

  @MutationAction
  async setLoadingBaseLayers(val: boolean) {
    return { LoadingBaseLayers: val };
  }

  @MutationAction
  async getBaseLayers() {
    const query = `?layer_type=base_layer`;
    const layers = await API.getLayers(query);
    return {
      BaseLayers: layers,
      LoadingBaseLayers: false
    };
  }

  @MutationAction
  async getLayers() {
    const query = ``;
    const layers = await API.getGroupedLayers(query);
    return {
      Layers: layers.filter((x: LayerInterface) => x.layerType != 'base_layer')
    };
  }

  @MutationAction
  async getGeoserverConfig() {
    const geoserverConfig = await API.getGeoserverConfig();
    return { geoserverBaseURL: geoserverConfig.baseUrl };
  }
}

export default getModule(MapStore);
