import { Page } from '@/store/models/page';

export enum UserTypes {
  ADMIN = 'admin',
  OWNER = 'owner',
  MANAGER = 'manager',
  CONSULTANT = 'consultant',
  MARKETER = 'marketer',
  PUBLIC = 'public'
}
export interface EnrollAdmin {
  uid: string;
  token: string;
  userDetail: RegisterUser;
}
export interface EnrollOwner {
  uid: string;
  token: string;
  userDetail: RegisterUser;
}

export interface EnrollManager {
  uid: string;
  farmUid: string;
  token: string;
  userDetail: RegisterUser;
}

export interface EnrollConsultant {
  uid: string;
  farmUid: string;
  token: string;
  userDetail: RegisterUser;
}

export interface EnrollMarketer {
  uid: string;
  farmUid: string;
  token: string;
  userDetail: RegisterUser;
}

export interface RegisterUser {
  username: string;
  firstName: string;
  lastName: string;
  password: string;
  password2: string;
  email: string;
  customUser: {
    profession: string;
    mobileNumber: number;
    locationName: string;
    photoId: string;
    pointGeom: PointGeom | string;
    role: string;
  };
}

export interface PointGeom {
  type: string;
  coordinates: number[];
}

export interface ChangePassword {
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
}

export interface ValidateInviteToken {
  uid: string;
  token: string;
  farmUid?: string;
  userType: UserTypes;
}

export interface ValidateInviteTokenResponse {
  isValid: boolean;
  message: string;
  farmName?: string;
  email: string;
  user: null | RegisterUser;
}

export interface UserDetail {
  id: number;
  username: string;
  fullName: string;
  firstName: string;
  lastName: string;
  email: string;
  dateJoined: string;
  lastLogin: string;
  isSuperuser: boolean;
  isActive: boolean;
  customUser: {
    id: number;
    profession: string;
    mobileNumber: number;
    locationName: string;
    photoId: string;
    photoIdThumbnail?: string;
    pointGeom: PointGeom;
    role: string;
    farms: number[];
    farmIds: number[];
  };
  parent?: UserRelation | null;
  children?: UserRelation[] | null;
}

export interface PaginatedUsers extends Page {
  results: UserDetail[];
}

export interface Role {
  value: string;
  label: string;
}

export interface SimpleUser {
  id: number;
  fullName: string;
  customUserId: number;
  role: string;
  parentId?: number | null;
}

export interface UserRelation {
  id: number;
  parent: number;
  child: number;
}

export interface ChangeRole {
  id: number;
  parent?: {
    id?: number;
    parent: number;
  };
  customUser: {
    role: string;
  };
}

export interface AssignFarm {
  id: number;
  farmIds: number[];
}

export interface Invitation {
  id: number;
  user: number;
  userEmail: string;
  token: string;
  userType: null;
  status: string;
  isExpired: boolean;
  invitedTo: null;
  createdDate: string;
  usedBy: null | UserDetail;
}

export interface PaginatedInvitations extends Page {
  results: Invitation[];
}

export interface DeleteInvitedUser {
  id: number;
  userId: number;
  password: string;
}
