import store from '@/store';
import jwtDecode from 'jwt-decode';
import {
  Action,
  getModule,
  Module,
  Mutation,
  MutationAction,
  VuexModule
} from 'vuex-module-decorators';

import router from '@/router';
import API from '@/store/api/jwt';
import {
  Activation,
  ConfirmResetPassword,
  DecodedJWTPayload,
  EmailReset,
  JWT,
  UserLoginInfo
} from '@/store/models/jwt';
import { UserDetail } from '@/store/models/user';
import { BlockStore, CommonStore } from '@/store/modules';
import Vue from 'vue';

const vm = new Vue();
@Module({ name: 'JwtStore', dynamic: true, store })
class JwtStore extends VuexModule {
  AccessToken = '';
  DecodedPayload: DecodedJWTPayload = {} as DecodedJWTPayload;
  isLoggedIn = false;
  RefreshingToken = true;
  UserDetail: UserDetail = {} as UserDetail;

  @Mutation
  refreshingToken() {
    this.RefreshingToken = true;
  }

  get loggedIn() {
    return this.isLoggedIn;
  }

  @MutationAction
  async getJWT(user: UserLoginInfo) {
    const detail = (await API.getJWT(user)) as JWT;
    const decodedPayload = jwtDecode(detail.access) as DecodedJWTPayload;
    const loginEvent = new CustomEvent('login', {
      bubbles: true
    });
    document.getRootNode().dispatchEvent(loginEvent);
    return {
      AccessToken: detail.access,
      DecodedPayload: decodedPayload,
      UserDetail: decodedPayload.userDetail,
      isLoggedIn: true
    };
  }

  @MutationAction
  async refreshAccessToken() {
    let detail = (await API.refreshAccessToken()) as JWT;

    // eslint-disable-next-line
    let decodedPayload: DecodedJWTPayload = {} as DecodedJWTPayload;
    let isLoggedIn = false;
    if (!detail || !detail.access) {
      const loggedIn = this.isLoggedIn;
      detail = {} as JWT;
      detail.access = '';
      decodedPayload = {} as DecodedJWTPayload;
      if (loggedIn) {
        router.push({ name: 'Home' });
      }
    } else {
      decodedPayload = jwtDecode(detail.access);
      isLoggedIn = true;
    }
    if (isLoggedIn) {
      const loginEvent = new CustomEvent('login', {
        bubbles: true
      });
      document.getRootNode().dispatchEvent(loginEvent);
    } else {
      const logoutEvent = new CustomEvent('logout', {
        bubbles: true
      });
      document.getRootNode().dispatchEvent(logoutEvent);
    }
    return {
      AccessToken: detail.access,
      DecodedPayload: decodedPayload,
      isLoggedIn: isLoggedIn,
      UserDetail: decodedPayload.userDetail,
      RefreshingToken: false
    };
  }

  @MutationAction
  async clearJWT() {
    const logoutEvent = new CustomEvent('logout', {
      bubbles: true
    });
    document.getRootNode().dispatchEvent(logoutEvent);
    await API.clearJWT();
    CommonStore.setSelectedFarmBlock({
      farmId: 0,
      name: '',
      center: [0, 0],
      extent: null
    });
    vm.$q.localStorage.remove('SelectedFarmBlock');
    BlockStore.clearAllMyBlocksWithGeom();
    return {
      AccessToken: '',
      DecodedPayload: {},
      isLoggedIn: false
    };
  }

  @Action
  async userActivation(data: Activation) {
    const response = await API.userActivation(data);
    return response;
  }

  @Action
  async resendActivation(data: EmailReset) {
    const response = await API.resendActivation(data);
    return response;
  }

  @Action
  async resetPassword(data: EmailReset) {
    const response = await API.resetPassword(data);
    return response;
  }

  @Action
  async confirmResetPassword(data: ConfirmResetPassword) {
    const response = await API.confirmResetPassword(data);
    return response;
  }

  @Action
  // eslint-disable-next-line
  async subscribe(data: any) {
    const response = await API.subscribe(data);
    return response;
  }
}

export default getModule(JwtStore);
