import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction
} from 'vuex-module-decorators';
import BlogAPI from '@/store/api/blog';
import { Blog, PaginatedBlog } from '@/store/models/blog';
import { Option } from '@/store/models/common';
import { QTablePagination } from '@/store/models/meta';

interface BlogQuery {
  farm?: number;
  page?: number;
  pageSize?: number;
  search?: string;
  ordering?: string;
  startDate?: string;
  endDate?: string;
  blog?: number | null;
  blogVariety?: number | null;
}
function getBlogQuery(params?: BlogQuery) {
  let query = '';
  if (params) {
    query += '?';
    if (params.blog) {
      query += `&id=${params.blog}`;
    }
    if (params.farm) {
      query += `&farm=${params.farm}`;
    }
    if (params.blogVariety) {
      query += `&blog__variety=${params.blogVariety}`;
    }
    if (params.blog) {
      query += `&blog=${params.blog}`;
    }
    if (params.page) {
      query += `&page=${params.page}`;
    }
    if (params.pageSize) {
      query += `&page_size=${params.pageSize}`;
    }
    if (params.search) {
      query += `&search=${params.search}`;
    }
    if (params.ordering) {
      query += `&ordering=${params.ordering}`;
    }
    if (params.startDate) {
      query += `&start_date=${params.startDate}`;
    }
    if (params.endDate) {
      query += `&end_date=${params.endDate}`;
    }
  }
  return query;
}

@Module({ dynamic: true, store, name: 'BlogStore' })
class BlogStore extends VuexModule {
  PaginatedBlogs: PaginatedBlog = {} as PaginatedBlog;
  emptyBlog: Blog = {} as Blog;
  AddEditBlog: Blog = {} as Blog;
  BlogCategories: Option[] = [] as Option[];

  BlogTablePagination: QTablePagination = {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  @MutationAction
  async setBlogTablePagination(data: QTablePagination) {
    return { BlogTablePagination: data };
  }

  @MutationAction
  async setAddEditBlog(blog: Blog) {
    return { AddEditBlog: blog };
  }

  @MutationAction
  async getBlogCategories() {
    const categories = await BlogAPI.getBlogCategories();
    return { BlogCategories: categories };
  }
  @MutationAction
  async getBlogs(params?: BlogQuery) {
    const query = getBlogQuery(params);
    const blogs = await BlogAPI.getBlogs(query);
    return { PaginatedBlogs: blogs };
  }
  @MutationAction
  async getMyBlogs(params?: BlogQuery) {
    const query = getBlogQuery(params);
    const blogs = await BlogAPI.getMyBlogs(query);
    return { PaginatedBlogs: blogs };
  }
  @MutationAction
  async getBlogDetail(id: number) {
    const blog = await BlogAPI.getBlogById(id);
    return { AddEditBlog: blog };
  }

  @MutationAction
  async createBlog(blog: FormData) {
    const addedBlog = await BlogAPI.createBlog(blog);
    const blogs = this.PaginatedBlogs;
    blogs.results.push(addedBlog);

    return { PaginatedBlogs: blogs, AddEditBlog: addedBlog };
  }

  @MutationAction
  async updateBlog(params: { id: number; blog: FormData }) {
    const updatedBlog = await BlogAPI.updateBlog(params.id, params.blog);

    const blogs = this.PaginatedBlogs;
    const blogIndex = blogs.results.findIndex(x => x.id == params.id);
    if (blogIndex != -1) {
      blogs.results.splice(blogIndex, 1, updatedBlog);
    }
    return {
      PaginatedBlogs: blogs,
      AddEditBlog: updatedBlog
    };
  }

  @MutationAction
  async deleteBlog(id: number) {
    await BlogAPI.deleteBlog(id);

    const emptyBlog = this.emptyBlog;

    const blogs = this.PaginatedBlogs;
    if (blogs) {
      const blogIndex = blogs.results.findIndex(x => x.id == id);
      if (blogIndex != -1) {
        blogs.results.splice(blogIndex, 1);
      }
    }

    return {
      PaginatedBlogs: blogs,
      AddEditBlog: JSON.parse(JSON.stringify(emptyBlog))
    };
  }
}

export default getModule(BlogStore);
