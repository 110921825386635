import store from '@/store';
import FarmAPI from '@/store/api/farm';
import { SelectedFarmBlock } from '@/store/models/common';
import { Farm, FarmManager, FarmSensor } from '@/store/models/farm';
import { QTablePagination } from '@/store/models/meta';
import { CommonStore, ConfigurationStore } from '@/store/modules';
import { Coordinate } from 'ol/coordinate';
import { Extent } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON';
import Vue from 'vue';
import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from 'vuex-module-decorators';

const vm = new Vue();
@Module({ name: 'FarmStore', dynamic: true, store })
class FarmStore extends VuexModule {
  Farms: Farm[] = [];
  emptyFarm: Farm = {
    manager: null,
    owner: null,
    managerList: [] as number[]
  } as Farm;
  AddEditFarm: Farm = {
    manager: null,
    owner: null,
    managerList: [] as number[]
  } as Farm;

  FarmSensors: FarmSensor[] = [];
  emptyFarmSensor: FarmSensor = {} as FarmSensor;
  AddEditFarmSensor: FarmSensor = {} as FarmSensor;

  FetchingDefaultFarm = true;

  FarmTablePagination: QTablePagination = {
    sortBy: 'id',
    descending: true,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  @MutationAction
  async setAddEditFarm(farm: Farm) {
    return { AddEditFarm: farm };
  }

  @MutationAction
  async setFarmTablePagination(data: QTablePagination) {
    return { FarmTablePagination: data };
  }

  @MutationAction
  async getAllMyFarms(params?: { farm?: number }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farm) {
        query += `&id=${params.farm}`;
      }
    }
    const farms: Farm[] = await FarmAPI.getAllMyFarms(query);
    for (const farm of farms) {
      farm.managerList = (farm.farmManager as FarmManager[])
        .filter(x => x.isActive)
        .map(x => x.manager);
    }
    return { Farms: farms };
  }

  @MutationAction
  async getMyDefaultFarm() {
    const selectedFarmBlock = vm.$q.localStorage.getItem(
      'SelectedFarmBlock'
    ) as SelectedFarmBlock | null;
    let farmId = 0;
    if (selectedFarmBlock) {
      await CommonStore.setSelectedFarmBlock(selectedFarmBlock);
      farmId = selectedFarmBlock.farmId;
    } else {
      const farm: Farm = await FarmAPI.getMyDefaultFarm();
      farmId = farm.id;
      if (farm.pointGeom && typeof farm.pointGeom != 'string') {
        let extent: Extent | null = null;
        if (farm.envelope) {
          const geometry = new GeoJSON({
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
          }).readGeometry(farm.envelope);
          extent = geometry.getExtent();
        }
        const selectedFarmBlock: SelectedFarmBlock = {
          farmId: farm.id,
          name: farm.name,
          center: farm.pointGeom.coordinates as Coordinate,
          extent: extent
        };
        CommonStore.setSelectedFarmBlock(selectedFarmBlock);
      }
    }
    if (farmId) {
      await ConfigurationStore.getLatestSeasonDateByFarm(farmId);
    }
    return { FetchingDefaultFarm: false };
  }

  @MutationAction
  async setFetchingDefaultFarm(value: boolean) {
    return { FetchingDefaultFarm: value };
  }

  @MutationAction
  async getFarmById(id: number) {
    const farm = await FarmAPI.getFarmById(id);
    farm.managerList = (farm.farmManager as FarmManager[])
      .filter(x => x.isActive)
      .map(x => x.manager);
    return { AddEditFarm: farm };
  }

  @MutationAction
  async createFarm(farm: Farm) {
    const addedFarm = await FarmAPI.createFarm(farm);
    const farms = this.Farms;
    farms.push(addedFarm);

    return { Farms: farms, AddEditFarm: addedFarm };
  }

  @MutationAction
  async updateFarm(farm: Farm) {
    const updatedFarm = await FarmAPI.updateFarm(farm);

    const farms = this.Farms;
    const farmIndex = farms.findIndex(x => x.id == farm.id);
    if (farmIndex != -1) {
      farms.splice(farmIndex, 1, updatedFarm);
    }
    return {
      Farms: farms,
      AddEditFarm: updatedFarm
    };
  }

  @MutationAction
  async deleteFarm(id: number) {
    await FarmAPI.deleteFarm(id);

    const emptyFarm = this.emptyFarm;

    const farms = this.Farms;
    if (farms) {
      const farmIndex = farms.findIndex(x => x.id == id);
      if (farmIndex != -1) {
        farms.splice(farmIndex, 1);
      }
    }

    return {
      Farms: farms,
      AddEditFarm: JSON.parse(JSON.stringify(emptyFarm))
    };
  }

  @MutationAction
  async setAddEditFarmSensor(farmSensor: FarmSensor) {
    return { AddEditFarmSensor: farmSensor };
  }

  @MutationAction
  async getAllFarmSensor(params?: { farm?: number }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farm) {
        query += `&farm=${params.farm}`;
      }
    }
    const farmSensors = await FarmAPI.getAllFarmSensor(query);
    return { FarmSensors: farmSensors };
  }

  @MutationAction
  async createFarmSensor(farmSensor: FarmSensor) {
    const addedFarmSensor = await FarmAPI.createFarmSensor(farmSensor);
    const newFarmSensor = this.FarmSensors;
    newFarmSensor.push(addedFarmSensor);

    return { FarmSensors: newFarmSensor, AddEditFarm: addedFarmSensor };
  }
}

export default getModule(FarmStore);
