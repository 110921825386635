import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction,
  Mutation,
  Action
} from 'vuex-module-decorators';
import ForumAPI from '@/store/api/forum';
import { QTablePagination } from '@/store/models/meta';
import {
  Discussion,
  DiscussionSummary,
  Forum,
  PaginatedDiscussion,
  PaginatedForum
} from '@/store/models/forum';

interface DiscussionQuery {
  page?: number;
  pageSize?: number;
  search?: string;
  ordering?: string;
  category?: string;
}
function getDiscussionQuery(params?: DiscussionQuery) {
  let query = '';
  if (params) {
    query += '?';
    if (params.category) {
      query += `&category=${params.category}`;
    }
    if (params.page) {
      query += `&page=${params.page}`;
    }
    if (params.pageSize) {
      query += `&page_size=${params.pageSize}`;
    }
    if (params.search) {
      query += `&search=${params.search}`;
    }
    if (params.ordering) {
      query += `&ordering=${params.ordering}`;
    }
  }
  return query;
}
interface ForumQuery {
  page?: number;
  pageSize?: number;
  search?: string;
  ordering?: string;
  category?: string;
  discussion?: number;
}
function getForumQuery(params?: ForumQuery) {
  let query = '';
  if (params) {
    query += '?';
    if (params.category) {
      query += `&category=${params.category}`;
    }
    if (params.page) {
      query += `&page=${params.page}`;
    }
    if (params.pageSize) {
      query += `&page_size=${params.pageSize}`;
    }
    if (params.search) {
      query += `&search=${params.search}`;
    }
    if (params.ordering) {
      query += `&ordering=${params.ordering}`;
    }
    if (params.discussion) {
      query += `&discussion=${params.discussion}`;
    }
  }
  return query;
}

@Module({ dynamic: true, store, name: 'ForumStore' })
class ForumStore extends VuexModule {
  emptyDiscussion: Discussion = {
    forum: {} as Forum
  } as Discussion;
  AddEditDiscussion: Discussion | null = null;
  PaginatedDiscussion: PaginatedDiscussion = {} as PaginatedDiscussion;
  DiscussionTablePagination: QTablePagination = {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  DiscussionSummary: DiscussionSummary = {} as DiscussionSummary;

  emptyForum: Forum = {} as Forum;
  AddEditForum: Forum | null = null;
  PaginatedForum: PaginatedForum = {} as PaginatedForum;
  ForumTablePagination: QTablePagination = {
    sortBy: 'id',
    descending: false,
    page: 1,
    rowsPerPage: 5,
    rowsNumber: 1
  };

  @Mutation
  setDiscussionTablePagination(data: QTablePagination) {
    this.DiscussionTablePagination = data;
  }

  @Mutation
  setAddEditDiscussion(discussion: Discussion) {
    this.AddEditDiscussion = discussion;
  }

  @Mutation
  setForumTablePagination(data: QTablePagination) {
    this.ForumTablePagination = data;
  }

  @Mutation
  setAddEditForum(forum: Forum) {
    this.AddEditForum = forum;
  }

  @MutationAction
  async getDiscussionSummary() {
    const summary = await ForumAPI.getDiscussionSummary();
    return {
      DiscussionSummary: summary
    };
  }

  @MutationAction
  async getAllDiscussions(params?: DiscussionQuery) {
    const query = getDiscussionQuery(params);
    const blocks = await ForumAPI.getAllDiscussion(query);
    return {
      PaginatedDiscussion: blocks
    };
  }

  @MutationAction
  async createDiscussion(data: FormData) {
    const discussion = await ForumAPI.createDiscussion(data);

    const paginatedDiscussion = this.PaginatedDiscussion;
    if (paginatedDiscussion.results) {
      paginatedDiscussion.results.push(discussion);
    }
    return {
      PaginatedDiscussion: paginatedDiscussion,
      AddEditDiscussion: discussion
    };
  }

  @MutationAction
  async updateDiscussion(params: { id: number; data: FormData }) {
    const discussion = await ForumAPI.updateDiscussion(params.id, params.data);
    const paginatedDiscussion = this.PaginatedDiscussion;
    if (paginatedDiscussion.results) {
      const discussionIndex = paginatedDiscussion.results.findIndex(
        x => x.id == params.id
      );
      if (discussionIndex != -1) {
        paginatedDiscussion.results.splice(discussionIndex, 1, discussion);
      }
    }
    return {
      PaginatedDiscussion: paginatedDiscussion,
      AddEditDiscussion: discussion
    };
  }

  @MutationAction
  async getDiscussionDetail(id: number) {
    const discussion = await ForumAPI.getDiscussionDetail(id);
    if (discussion.firstForum) {
      discussion.forum = discussion.firstForum;
      delete discussion.forum.children;
    }
    return {
      AddEditDiscussion: discussion
    };
  }
  @MutationAction
  async closeDiscussion(id: number) {
    const discussion = await ForumAPI.closeDiscussion(id);
    if (discussion.firstForum) {
      discussion.forum = discussion.firstForum;
      delete discussion.forum.children;
    }
    return {
      AddEditDiscussion: discussion
    };
  }

  @MutationAction
  async getForumByDiscussion(params: ForumQuery) {
    const query = getForumQuery(params);
    const forums = await ForumAPI.getForums(query);
    return {
      PaginatedForum: forums
    };
  }

  @MutationAction
  async getForumDetail(id: number) {
    const forum = await ForumAPI.getForumDetail(id);
    // if (forum) {
    //   delete forum.children;
    // }
    return {
      AddEditForum: forum
    };
  }

  @Action
  async readForumDetail(id: number) {
    return await ForumAPI.getForumDetail(id);
  }

  @MutationAction
  async updateForum(params: { id: number; data: FormData }) {
    const forum = await ForumAPI.updateForum(params.id, params.data);
    const paginatedForum = this.PaginatedForum;
    if (paginatedForum.results) {
      const forumIndex = paginatedForum.results.findIndex(
        x => x.id == params.id
      );
      if (forumIndex != -1) {
        paginatedForum.results.splice(forumIndex, 1, forum);
      }
    }
    return {
      PaginatedForum: paginatedForum,
      AddEditForum: forum
    };
  }

  @MutationAction
  async replyForum(params: { id: number; data: FormData }) {
    const forum = await ForumAPI.replyForum(params.id, params.data);
    const paginatedForum = this.PaginatedForum;
    if (paginatedForum.results) {
      paginatedForum.results.push(forum);
    }
    return {
      PaginatedForum: paginatedForum,
      AddEditForum: forum
    };
  }
}

export default getModule(ForumStore);
