import store from '@/store';
import FieldBinAPI from '@/store/api/fieldBin';
import { DeleteFieldBin } from '@/store/models/fieldBin';
import {
  Action,
  Module,
  MutationAction,
  VuexModule,
  getModule
} from 'vuex-module-decorators';

@Module({ name: 'FieldBinStore', dynamic: true, store })
class FieldBinStore extends VuexModule {
  FieldBinBatchUploadDialog = false;
  FieldBinDownloadDialog = false;

  FieldBinDeleteDialog = false;

  @MutationAction
  async setFieldBinBatchUploadDialog(value = false) {
    return { FieldBinBatchUploadDialog: value };
  }

  @MutationAction
  async setFieldBinDownloadDialog(value = false) {
    return { FieldBinDownloadDialog: value };
  }

  @MutationAction
  async setFieldBinDeleteDialog(value = false) {
    return { FieldBinDeleteDialog: value };
  }

  @Action
  async batchUploadFieldBin(params: { data: FormData }) {
    const response = await FieldBinAPI.batchUploadFieldBin(params.data);
    return response;
  }

  @Action
  async updateFieldBinProcessingStatus(id: number) {
    const response = await FieldBinAPI.updateFieldBinProcessingStatus(id);
    return response;
  }

  // @Action
  // async canUploadFieldBinImage(data: CanUploadFieldBinImage) {
  //   const response = await FieldBinAPI.canUploadFieldBinImage(data);
  //   return response;
  // }

  // @Action
  // async canUploadMultipleFieldBinImage(data: CanUploadFieldBinImage[]) {
  //   const response = await FieldBinAPI.canUploadMultipleFieldBinImage(data);
  //   return response;
  // }

  @Action
  async findBatchDeleteFieldBin(data: DeleteFieldBin) {
    const response = await FieldBinAPI.findBatchDeleteFieldBin(data);
    return response;
  }

  @Action
  async batchDeleteFieldBin(data: DeleteFieldBin) {
    console.log(data);

    const response = await FieldBinAPI.batchDeleteFieldBin(data);
    return response;
  }

  @Action
  async downloadFieldBinData(params: { data: FormData }) {
    const response = await FieldBinAPI.downloadFieldBinData(params.data);
    return response;
  }
}

export default getModule(FieldBinStore);
