import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction,
  Action
} from 'vuex-module-decorators';
import NotificationAPI from '@/store/api/notification';
import {
  PaginatedNotifications,
  NotificationCount
} from '@/store/models/notification';
import { QTablePagination } from '@/store/models/meta';

@Module({ name: 'NotificationStore', dynamic: true, store })
class NotificationStore extends VuexModule {
  PaginatedNotifications: PaginatedNotifications = {} as PaginatedNotifications;
  NotificationCount: NotificationCount = {} as NotificationCount;

  NotificationTablePagination: QTablePagination = {
    sortBy: 'created_date',
    descending: true,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  @MutationAction
  async setNotificationTablePagination(data: QTablePagination) {
    return { NotificationTablePagination: data };
  }

  @Action
  async MarkNotificationAsRead(id: number) {
    return await NotificationAPI.markNotificationAsRead(id);
  }

  @Action
  async MarkNotificationAsUnRead(id: number) {
    return await NotificationAPI.markNotificationAsUnRead(id);
  }

  @Action
  async MarkAllNotificationAsRead() {
    return await NotificationAPI.markAllNotificationAsRead();
  }

  @MutationAction
  async getNotificationCount() {
    const notificationCount = await NotificationAPI.getNotificationCount();
    return { NotificationCount: notificationCount };
  }

  @MutationAction
  async getNotifications(params?: {
    page?: number;
    search?: string;
    ordering?: string;
    pageSize?: number;
  }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.page) {
        query += `&page=${params.page}`;
      }
      if (params.search) {
        query += `&search=${params.search}`;
      }
      if (params.ordering) {
        query += `&ordering=${params.ordering}`;
      }
      if (params.pageSize) {
        query += `&page_size=${params.pageSize}`;
      }
    }
    const paginatedNotifications = await NotificationAPI.getNotifications(
      query
    );
    return { PaginatedNotifications: paginatedNotifications };
  }
}

export default getModule(NotificationStore);
