import store from '@/store';
import { Module, VuexModule, getModule, Action } from 'vuex-module-decorators';
import {
  DiaryStore,
  FruitSizingStore,
  MapStore,
  MapStyleStore,
  SensorStore
} from '@/store/modules';

import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { VectorTile as VectorTileLayer } from 'ol/layer';

@Module({ dynamic: true, name: 'MapLayerStore', store })
class MapLayerStore extends VuexModule {
  get map() {
    return MapStore.mapState;
  }

  @Action
  async getVectorLayer(layerID: string) {
    const layers = await this.map.getLayers().getArray();
    // eslint-disable-next-line
    let l = layers[0] as VectorLayer<any>;
    for (const layer of layers) {
      if (layer.get('id') === layerID) {
        // eslint-disable-next-line
        l = layer as VectorLayer<any>;
        return l;
      }
    }
    return null;
  }

  @Action
  async getVectorTileLayer(layerID: string) {
    const layers = await this.map.getLayers().getArray();
    let l = layers[0] as VectorTileLayer;
    for (const layer of layers) {
      if (layer.get('id') === layerID) {
        l = layer as VectorTileLayer;
        return l;
      }
    }
    return null;
  }

  /** Fruit Sizing Layer Start */
  get fruitSizing() {
    return FruitSizingStore.FruitSizings;
  }

  get fruitSizingLocation() {
    const fruitSizingLocations = [];
    for (const fruitSizing of this.fruitSizing) {
      const feature = new Feature({
        geometry: new Point([
          fruitSizing.geolocation.x,
          fruitSizing.geolocation.y
        ])
      });
      feature.setId(fruitSizing.id);
      feature.setProperties({
        type: 'fruitSizing',
        id: fruitSizing.id,
        photo: fruitSizing.photo,

        fruitSizeLength: fruitSizing.fruitSizeLength,
        fruitSizeWidth: fruitSizing.fruitSizeWidth,
        fruitSizeHeight: fruitSizing.fruitSizeHeight,

        block: fruitSizing.block,
        fruitType: fruitSizing.fruitType,
        cameraToBoardDist: fruitSizing.cameraToBoardDist,
        diameterToRefrenceCircle: fruitSizing.diameterToRefrenceCircle,
        dateCaptured: new Date(fruitSizing.dateCaptured).toLocaleString(),
        thumbnail: fruitSizing.photoThumbnail.thumbnail
      });
      feature.setStyle(MapStyleStore.fruitSizingStyle);
      fruitSizingLocations.push(feature);
    }
    return fruitSizingLocations;
  }

  @Action
  async setFruitSizingLayer() {
    await FruitSizingStore.getAllFruitSizing();
    let fruitSizingLayer = await this.getVectorLayer('FruitSizingLayer');

    const fruitSizingSource = new VectorSource({
      features: this.fruitSizingLocation
    });
    if (fruitSizingLayer && fruitSizingLayer != null) {
      console.log('Old', fruitSizingLayer);
      // fruitSizingLayer.getSource().clear();
      // fruitSizingLayer.getSource().addFeatures(this.fruitSizingLocation);
    } else {
      fruitSizingLayer = new VectorLayer({
        source: fruitSizingSource
      });
      fruitSizingLayer.set('id', 'FruitSizingLayer');
      this.map.addLayer(fruitSizingLayer);
    }
  }
  /** Fruit Sizing Layer End */

  /** Diary Layer Start */
  get diary() {
    return DiaryStore.Diaries;
  }

  get diaryLocation() {
    const diaryLocations = [];
    for (const diary of this.diary) {
      const feature = new Feature({
        geometry: new Point([diary.geolocation.x, diary.geolocation.y])
      });
      feature.setId(diary.id);
      feature.setProperties({
        type: 'diary',
        id: diary.id,
        photo: diary.photo,

        comment: diary.comment,

        block: diary.block,
        fruitType: diary.fruitType,
        dateCaptured: new Date(diary.dateCaptured).toLocaleString(),
        thumbnail: diary.photoThumbnail.thumbnail
      });
      feature.setStyle(MapStyleStore.diaryStyle);
      diaryLocations.push(feature);
    }
    return diaryLocations;
  }

  @Action
  async setDiaryLayer() {
    await DiaryStore.getAllDiary();

    let diaryLayer = await this.getVectorLayer('Diary Layer');

    const diarySource = new VectorSource({
      features: this.diaryLocation
    });
    if (diaryLayer && diaryLayer != null) {
      diaryLayer.getSource().clear();
      diaryLayer.getSource().addFeatures(this.diaryLocation);
    } else {
      diaryLayer = new VectorLayer({
        source: diarySource
      });
      diaryLayer.set('id', 'Diary Layer');
      this.map.addLayer(diaryLayer);
    }
  }
  /** Diary Layer End */

  /** Sensor Layer Start */

  get sensors() {
    return SensorStore.Sensors;
  }

  get sensorLocation() {
    const sensorLocations = [];
    for (const sensor of this.sensors) {
      const feature = new GeoJSON().readFeature(sensor.geom);
      feature.setId(sensor.id);
      feature.setProperties({
        type: 'sensor',
        id: sensor.id
      });
      sensorLocations.push(feature);
    }
    return sensorLocations;
  }

  @Action
  async setSensorLayer() {
    let sensorLayer = await this.getVectorLayer('Sensor Layer');

    const sensorSource = new VectorSource({
      features: this.sensorLocation
    });
    if (sensorLayer && sensorLayer != null) {
      sensorLayer.getSource().clear();
      sensorLayer.getSource().addFeatures(this.sensorLocation);
    } else {
      sensorLayer = new VectorLayer({
        source: sensorSource
      });
      sensorLayer.set('id', 'Sensor Layer');
      this.map.addLayer(sensorLayer);
    }
  }
  /** Sensor Layer End */
}

export default getModule(MapLayerStore);
