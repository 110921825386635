import { Coordinate } from 'ol/coordinate';

export interface GPSLocation {
  latitude: number;
  longitude: number;
}

export enum PanicleCount {
  'TOTAL_COUNT' = 'totalCount',
  'X_COUNT_AVG' = 'xCountAvg',
  'Y_COUNT_AVG' = 'yCountAvg',
  'Z_COUNT_AVG' = 'zCountAvg'
}

export enum SliderType {
  'POINT_OF_TIME' = 'pointOfTime',
  'PERIOD_OF_TIME' = 'periodOfTime'
}

export enum DynamicLayerName {
  FLOWERING_X = 'Elongation (X)',
  FLOWERING_Y = 'Christmas tree (Y)',
  FLOWERING_Z = 'Flower drop (Z)',
  FLOWERING_XY = 'Flowering XY',
  FLOWERING_YZ = 'Flowering YZ',
  FLOWERING_XYZ = 'Flowering XYZ',
  FLOWERING_PHOTO = 'Flowering Photo',
  FRUIT_COUNT = 'Fruit Count',
  FRUIT_PHOTO = 'Fruit Photo',
  FIELD_BINS = 'Field Bins',
  DRY_MATTER = 'Dry Matter',
  DRY_MATTER_BLOCK = 'Dry Matter Block',
  FRUIT_SIZE = 'Fruit Size',
  FRUIT_SIZE_PHOTO = 'Fruit Size Photo'
}
export interface PointGeom {
  type: string;
  coordinates: Coordinate;
  properties?: {
    // eslint-disable-next-line
    [key: string]: any;
  };
}
export interface Geom {
  type: string;
  coordinates: Coordinate | Coordinate[] | Coordinate[][] | Coordinate[][][];
  properties?: {
    // eslint-disable-next-line
    [key: string]: any;
  };
}

export interface LayerInterface {
  id: number;
  order: number;
  url: string;
  baseUrl: string;
  layer: string;
  layerType: string;
  layerId: string;
  layerTitle: string;
  srs: string;
  defaultVisible: boolean;
  legendUrl: string;
  zIndex: number;
  layerDescription: string;
  sourceDescription: string;
}

export interface LayerGroupInterface {
  id: number;
  groupId: number;
  order: number;
  name: string;
  groupType: string;
  defaultVisible: boolean;
  defaultSelected?: string;
  layers?: LayerInterface[];
}
