import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction,
  Mutation
} from 'vuex-module-decorators';
import { MapStore } from '@/store/modules';
import { SelectedFarmBlock } from '@/store/models/common';
import Vue from 'vue';
import { Season } from '../models/configuration';

const vm = new Vue();

@Module({ name: 'CommonStore', dynamic: true, store })
class CommonStore extends VuexModule {
  View = 'map';
  SelectedFarmBlock: SelectedFarmBlock = {
    farmId: 0,
    name: '',
    blockName: '',
    center: [150.5155619, -23.322713]
  } as SelectedFarmBlock;

  CurrentSeason: Season | null = null;
  MapTableView = false;

  CurrentCropVariety: number | null = null;
  CurrentCrop: number | null = null;
  StartDate = '';
  EndDate = '';

  TimerStartDate = '';
  TimerEndDate = '';
  MapViewTab = 'Flowering';

  CalendarDayList: {
    [key: number]: {
      name: string;
      shortName: string;
      days: number;
    };
  } = {
    1: {
      name: 'January',
      shortName: 'Jan',
      days: 31
    },
    2: {
      name: 'February',
      shortName: 'Feb',
      days: 29
    },
    3: {
      name: 'March',
      shortName: 'Mar',
      days: 31
    },
    4: {
      name: 'April',
      shortName: 'Apr',
      days: 30
    },
    5: {
      name: 'May',
      shortName: 'May',
      days: 31
    },
    6: {
      name: 'June',
      shortName: 'Jun',
      days: 30
    },
    7: {
      name: 'July',
      shortName: 'Jul',
      days: 31
    },
    8: {
      name: 'August',
      shortName: 'Aug',
      days: 31
    },
    9: {
      name: 'September',
      shortName: 'Sep',
      days: 30
    },
    10: {
      name: 'October',
      shortName: 'Oct',
      days: 31
    },
    11: {
      name: 'November',
      shortName: 'Nov',
      days: 30
    },
    12: {
      name: 'December',
      shortName: 'Dec',
      days: 31
    }
  };

  @MutationAction
  async changeView(view: string) {
    MapStore.updateMapSize();

    setTimeout(() => {
      MapStore.stopUpdateAnimation();
    }, 600);
    return { View: view };
  }
  @MutationAction
  async setSelectedFarmBlock(value: SelectedFarmBlock) {
    if (value.farmId && value.farmId != 0) {
      vm.$q.localStorage.set('SelectedFarmBlock', value);
    } else {
      // vm.$q.localStorage.remove('SelectedFarmBlock');
    }
    return { SelectedFarmBlock: value };
  }
  @MutationAction
  async setCurrentCropVariety(value: number | null) {
    return { CurrentCropVariety: value };
  }
  @MutationAction
  async setCurrentCrop(value: number | null) {
    return { CurrentCrop: value };
  }
  @MutationAction
  async setCurrentSeason(value: Season | null) {
    return { CurrentSeason: value };
  }
  @MutationAction
  async setMapTableView(value: boolean) {
    return { MapTableView: value };
  }
  @MutationAction
  async setStartDate(value: string) {
    return { StartDate: value };
  }
  @MutationAction
  async setEndDate(value: string) {
    return { EndDate: value };
  }
  @Mutation
  setTimerStartDate(value: string) {
    this.TimerStartDate = value;
  }
  @Mutation
  setTimerEndDate(value: string) {
    this.TimerEndDate = value;
  }

  @MutationAction
  async setMapViewTab(tab: string) {
    return { MapViewTab: tab };
  }
}

export default getModule(CommonStore);
