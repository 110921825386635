import store from '@/store';
import { Module, VuexModule, getModule } from 'vuex-module-decorators';
import { RawChart } from '@/store/models/charts';

@Module({ name: 'ChartStore', dynamic: true, store })
class ChartStore extends VuexModule {
  // eslint-disable-next-line
  BaseColumnChart: any = {
    title: {
      text: ''
    },
    chart: {
      type: 'column'
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'downloadPNG',
            'downloadJPEG',
            'downloadCSV'
          ]
        }
      },
      csv: {
        dateFormat: '%Y-%m-%d'
      }
    },
    xAxis: {
      title: null,
      categories: [],
      type: 'category'
    },
    yAxis: {
      title: ''
    },
    series: [],
    plotOptions: {
      series: {
        stacking: null,
        dataLabels: {
          enabled: true,
          overflow: 'justify'
        }
      }
    }
  };
  // eslint-disable-next-line
  BaseLineChart: any = {
    title: {
      text: ''
    },
    chart: {
      type: 'line'
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'downloadPNG',
            'downloadJPEG',
            'downloadCSV'
          ]
        }
      },
      csv: {
        dateFormat: '%Y-%m-%d'
      }
    },
    xAxis: {
      title: null,
      categories: [],
      type: 'category'
    },
    yAxis: {
      title: ''
    },
    series: [],
    plotOptions: {
      series: {
        stacking: null,
        dataLabels: {
          enabled: true,
          overflow: 'justify'
        }
      }
    }
  };
  // eslint-disable-next-line
  BaseSplineChart: any = {
    title: {
      text: ''
    },
    chart: {
      type: 'spline'
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'downloadPNG',
            'downloadJPEG',
            'downloadCSV'
          ]
        }
      },
      csv: {
        dateFormat: '%Y-%m-%d'
      }
    },
    xAxis: {
      title: null,
      categories: [],
      type: 'category'
    },
    yAxis: {
      title: ''
    },
    series: [],
    plotOptions: {
      series: {
        stacking: null,
        dataLabels: {
          enabled: true,
          overflow: 'justify'
        }
      }
    }
  };
  // eslint-disable-next-line
  BaseBarChart: any = {
    title: {
      text: ''
    },
    chart: {
      type: 'bar'
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'downloadPNG',
            'downloadJPEG',
            'downloadCSV'
          ]
        }
      },
      csv: {
        dateFormat: '%Y-%m-%d'
      }
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    series: [],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          overflow: 'justify'
        }
      }
    }
  };
  // eslint-disable-next-line
  BaseScatterChart: any = {
    title: {
      text: ''
    },
    chart: {
      type: 'scatter'
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'downloadPNG',
            'downloadJPEG',
            'downloadCSV'
          ]
        }
      },
      csv: {
        dateFormat: '%Y-%m-%d'
      }
    },
    xAxis: {
      type: 'category',
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true
    },
    yAxis: {
      title: {
        text: ''
      }
    },
    series: [],
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          overflow: 'justify'
        }
      }
    }
  };
  // eslint-disable-next-line
  BasePieChart: any = {
    title: {
      text: ''
    },
    chart: {
      type: 'pie'
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'downloadPNG',
            'downloadJPEG',
            'downloadCSV'
          ]
        }
      },
      csv: {
        dateFormat: '%Y-%m-%d'
      }
    },
    series: []
  };
  // eslint-disable-next-line
  BasePyramidChart: any = {
    title: {
      text: ''
    },
    chart: {
      type: 'bar'
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'downloadPNG',
            'downloadJPEG',
            'downloadCSV'
          ]
        }
      },
      csv: {
        dateFormat: '%Y-%m-%d'
      }
    },
    xAxis: [],
    plotOptions: {
      series: {
        stacking: 'normal',
        dataLabels: {
          enabled: false,
          overflow: 'justify'
        }
      }
    },
    tooltip: {},
    yAxis: {
      title: 'Count'
    },
    series: []
  };

  get ColumnChartData() {
    return (rawData: RawChart) => {
      // Need to deep copy BaseChart else vue will go into infinite loop
      const preData = JSON.parse(JSON.stringify(this.BaseColumnChart));
      if (rawData && rawData.series) {
        preData.title.text = rawData.title;
        // let index = 0;
        for (const [seriesName, seriesData] of Object.entries(rawData.series)) {
          // preData.xAxis.categories = Object.keys(seriesData);
          preData.series.push({
            name: seriesName,
            data: Object.entries(seriesData).map(x => [x[0], x[1]]),
            // color: getters.highChartColors[index],
            dataSorting: {
              enabled: true,
              sortKey: 'y'
            }
          });
          // index++;
        }
      }
      return preData;
    };
  }
  get BarChartData() {
    return (rawData: RawChart) => {
      // Need to deep copy BaseChart else vue will go into infinite loop
      const preData = JSON.parse(JSON.stringify(this.BaseBarChart));
      if (rawData && rawData.series) {
        preData.title.text = rawData.title;
        // let index = 0;
        for (const [seriesName, seriesData] of Object.entries(rawData.series)) {
          preData.series.push({
            name: seriesName,
            data: Object.entries(seriesData).map(x => [x[0], x[1]]),
            // color: this.highChartColors[index],
            dataLabels: {
              enabled: true
            },
            dataSorting: {
              enabled: true,
              sortKey: 'y'
            }
          });
          // index++;
        }
      }
      return preData;
    };
  }

  get ScatterChartData() {
    return (rawData: RawChart) => {
      // Need to deep copy BaseChart else vue will go into infinite loop
      const preData = JSON.parse(JSON.stringify(this.BaseScatterChart));

      if (rawData && rawData.series) {
        preData.title.text = rawData.title;
        // let index = 0;
        for (const [seriesName, seriesData] of Object.entries(rawData.series)) {
          preData.series.push({
            name: seriesName,
            data: Object.entries(seriesData).map(x => [
              Number(x[0]),
              Number(x[1])
            ]),
            marker: {
              symbol: 'circle'
            }
          });
          // index++;
        }
      }
      return preData;
    };
  }

  get SplineChartData() {
    return (rawData: RawChart) => {
      // Need to deep copy BaseChart else vue will go into infinite loop
      const preData = JSON.parse(JSON.stringify(this.BaseColumnChart));
      if (rawData && rawData.series) {
        preData.title.text = rawData.title;
        for (const [seriesName, seriesData] of Object.entries(rawData.series)) {
          // preData.xAxis.categories = Object.keys(seriesData as any);
          preData.series.push({
            type: 'spline',
            name: seriesName,
            data: Object.entries(seriesData).map(x => {
              try {
                return [Number(x[0]), x[1]];
              } catch {
                return [x[0], x[1]];
              }
            })
          });
        }
      }
      return preData;
    };
  }

  get SplineChartDataWithDots() {
    return (rawData: RawChart) => {
      // Need to deep copy BaseChart else vue will go into infinite loop
      const preData = JSON.parse(JSON.stringify(this.BaseSplineChart));
      if (rawData && rawData.series) {
        preData.title.text = rawData.title;
        for (const [seriesName, seriesData] of Object.entries(rawData.series)) {
          // preData.xAxis.categories = Object.keys(seriesData as any);
          preData.series.push({
            // type: 'spline',
            name: seriesName,
            marker: {
              enabled: true,
              symbol: 'circle',
              radius: 2,
              lineWidth: 1,
              lineColor: '#000'
            },
            data: Object.entries(seriesData).map(x => {
              let x0: string | number = Number(x[0]);
              if (!x0) {
                x0 = x[0];
              }
              const x1 = x[1];
              return [x0, x1];
            })
          });
        }
      }
      return preData;
    };
  }

  get SplineChartDataNoDots() {
    return (rawData: RawChart) => {
      // Need to deep copy BaseChart else vue will go into infinite loop
      const preData = JSON.parse(JSON.stringify(this.BaseSplineChart));
      if (rawData && rawData.series) {
        preData.title.text = rawData.title;
        for (const [seriesName, seriesData] of Object.entries(rawData.series)) {
          // preData.xAxis.categories = Object.keys(seriesData as any);
          preData.series.push({
            // type: 'spline',
            name: seriesName,
            marker: {
              enabled: false
            },
            data: Object.entries(seriesData).map(x => {
              let x0: string | number = Number(x[0]);
              if (!x0) {
                x0 = x[0];
              }
              const x1 = x[1];
              return [x0, x1];
            })
          });
        }
      }
      return preData;
    };
  }

  get LineChartDataWithDots() {
    return (rawData: RawChart) => {
      // Need to deep copy BaseChart else vue will go into infinite loop
      const preData = JSON.parse(JSON.stringify(this.BaseLineChart));
      if (rawData && rawData.series) {
        preData.title.text = rawData.title;
        for (const [seriesName, seriesData] of Object.entries(rawData.series)) {
          // preData.xAxis.categories = Object.keys(seriesData as any);
          preData.series.push({
            // type: 'spline',
            name: seriesName,
            marker: {
              enabled: true,
              symbol: 'circle',
              // radius: 2,
              lineWidth: 1,
              lineColor: '#000'
            },
            data: Object.entries(seriesData).map(x => {
              let x0: string | number = Number(x[0]);
              if (!x0) {
                x0 = x[0];
              }
              const x1 = x[1];
              return [x0, x1];
            })
          });
        }
      }
      return preData;
    };
  }
  //   getPieChartData: (state, getters) => (rawData) => {
  //     // Need to deep copy BaseChart else vue will go into infinite loop
  //     let preData = JSON.parse(JSON.stringify(state.BasePieChart));
  //     if (rawData && rawData.series) {
  //       preData.title.text = rawData.title;
  //       for (let [seriesName, seriesData] of Object.entries(rawData.series)) {
  //         let index = 0;
  //         let tempData = [];
  //         for (let [key, value] of Object.entries(seriesData)) {
  //           tempData.push({
  //             name: key,
  //             y: value,
  //             color: getters.highChartColors[index],
  //           });
  //           index++;
  //         }
  //         preData.series.push({
  //           name: seriesName,
  //           data: tempData,
  //         });
  //       }
  //     }
  //     return preData;
  //   }
  //   getPyramidChartData: (state, getters) => (rawData) => {
  //     // Need to deep copy BaseChart else vue will go into infinite loop
  //     let preData = JSON.parse(JSON.stringify(state.BasePyramidChart));
  //     if (rawData && rawData.series) {
  //       // Check and add missing keys on all properties
  //       let allProperties = {};
  //       for (let seriesData of Object.values(rawData.series)) {
  //         allProperties = { ...allProperties, ...seriesData };
  //       }
  //       const allKeys = Object.keys(allProperties);
  //       for (let seriesData of Object.values(rawData.series)) {
  //         let seriesKeys = Object.keys(allKeys);
  //         if (allKeys.length != seriesKeys) {
  //           for (let key of allKeys) {
  //             if (!seriesData[key]) {
  //               seriesData[key] = 0;
  //             }
  //           }
  //         }
  //       }
  //       // End of checking and adding missings keys

  //       preData.title.text = rawData.title;
  //       let index = 0;
  //       for (let [seriesName, seriesData] of Object.entries(rawData.series)) {
  //         let opposite = rawData.opposites
  //           ? rawData.opposites[seriesName]
  //           : false;

  //         let data = Object.values(seriesData);
  //         // Make opposite data negative
  //         if (rawData.opposites && !opposite) {
  //           data = data.map((x) => x * -1);
  //         }
  //         // Category data
  //         let xAxisData = {
  //           categories: Object.keys(seriesData),
  //           reversed: false,
  //           labels: {
  //             step: 1,
  //           },
  //           accessibility: {
  //             description: seriesName
  //           },
  //           opposite: opposite,
  //         }
  //         if (index > 0) {
  //           xAxisData.linkedTo = 0
  //         }
  //         preData.xAxis.push(xAxisData);
  //         // Series data
  //         let series = {
  //           name: seriesName,
  //           data: data,
  //           color: getters.highChartColors[index],
  //         }
  //         preData.series.push(series);
  //         index++;
  //       }
  //     }
  //     return preData;
  //   }
}

export default getModule(ChartStore);
