import store from '@/store';
import { Module, VuexModule, getModule } from 'vuex-module-decorators';
import {
  Fill,
  Stroke,
  Circle,
  Style,
  Icon,
  Text,
  RegularShape
} from 'ol/style';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
import Point from 'ol/geom/Point';
import { BlockStore } from '@/store/modules';

@Module({ name: 'MapStyleStore', dynamic: true, store })
class MapStyleStore extends VuexModule {
  fill = new Fill({
    color: 'rgba(255,255,255,0.4)'
  });

  transparentFill = new Fill({
    color: 'rgba(255,255,255,0)'
  });

  stroke = new Stroke({
    color: '#3399CC',
    width: 1.25
  });

  normalStyle = new Style({
    image: new Circle({
      fill: this.fill,
      stroke: this.stroke,
      radius: 5
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitSizingStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(0,255,0,0.4)'
      }),
      stroke: new Stroke({
        color: '#0f0',
        width: 1.25
      }),
      radius: 5
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  diaryStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(0,0,255,0.4)'
      }),
      stroke: new Stroke({
        color: '#00f',
        width: 1.25
      }),
      radius: 5
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  flowerPanicle0Style = new Style({
    image: new Circle({
      radius: 2, //width * 2,
      fill: new Fill({
        color: 'rgba(2, 145, 6, 0.6)'
      }),
      stroke: new Stroke({
        color: '',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  flowerPanicleL30Style = new Style({
    image: new Circle({
      radius: 2, //width * 2,
      fill: new Fill({
        color: 'rgba(196, 248, 18, 0.6)'
      }),
      stroke: new Stroke({
        color: '#c4f812',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  flowerPanicleL70Style = new Style({
    image: new Circle({
      radius: 2, //width * 2,
      fill: new Fill({
        color: 'rgba(248, 125, 17, 0.6)'
      }),
      stroke: new Stroke({
        color: '#f87d11',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  flowerPanicleG70Style = new Style({
    image: new Circle({
      radius: 2, //width * 2,
      fill: new Fill({
        color: 'rgba(227, 43, 6, 0.6)'
      }),
      stroke: new Stroke({
        color: '#e32b06',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  floweringStyle = new Style({
    image: new Circle({
      radius: 2, //width * 2,
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.6)'
      }),
      stroke: new Stroke({
        color: '#319FD3',
        width: 1 //width / 2
      })
    })
    //,zIndex: Infinity
  });

  floweringHighlightStyle = new Style({
    image: new Circle({
      radius: 2, //width * 2,
      fill: new Fill({
        color: 'rgba(255,0,0,0.1)'
      }),
      stroke: new Stroke({
        color: '#00f',
        width: 2 //width / 2
      })
    }),
    zIndex: Infinity
  });

  get blockStyle() {
    // eslint-disable-next-line
    return (feature: any) => {
      const style = new Style({
        image: new Circle({
          fill: new Fill({
            color: 'rgba(0,0,0,0)'
          }),
          stroke: new Stroke({
            color: '#f0f',
            width: 1.25
          }),
          radius: 5
        }),
        fill: this.transparentFill,
        stroke: this.stroke
      });
      if (feature) {
        style.setText(
          new Text({
            text: `${feature.get('name')}`,
            font: 'Block 10px Arial',
            fill: new Fill({ color: '#000' }),
            stroke: new Stroke({ color: '#fff', width: 3 })
          })
        );
      }
      return style;
    };
  }

  get blockCreateStyle() {
    // eslint-disable-next-line
    return (feature: any) => {
      const style = new Style({
        image: new Circle({
          fill: new Fill({
            color: 'rgba(0,0,0,0)'
          }),
          stroke: new Stroke({
            color: '#f0f',
            width: 1.25
          }),
          radius: 5
        }),
        fill: this.fill,
        stroke: this.stroke
      });
      if (feature) {
        style.setText(
          new Text({
            text: `${feature.get('name')}`,
            font: 'Block 10px Arial',
            fill: new Fill({ color: '#000' }),
            stroke: new Stroke({ color: '#fff', width: 3 })
          })
        );
      }
      return style;
    };
  }

  get sensorStyle() {
    return (feature: any) => {
      const style = new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          src: require('@/assets/temperature.svg'),
          scale: 0.04
        })
      });
      if (feature) {
        style.setText(
          new Text({
            text: `${feature.get('locationName')}`,
            offsetY: -18,
            font: '12px Calibri, sans-srif',
            overflow: true,
            fill: new Fill({
              color: '#000'
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 4
            })
          })
        );
      }
      return style;
    };
  }

  get metStationStyle() {
    return (feature: any) => {
      const style = new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          anchorXUnits: IconAnchorUnits.FRACTION,
          anchorYUnits: IconAnchorUnits.FRACTION,
          src: require('@/assets/station.svg'),
          scale: 0.04
        })
      });
      if (feature) {
        style.setText(
          new Text({
            text: `${feature.get('location')}`,
            offsetY: -18,
            font: '12px Calibri, sans-srif',
            overflow: true,
            fill: new Fill({
              color: '#000'
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 4
            })
          })
        );
      }
      return style;
    };
  }

  sensorBlockLinkage = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.FRACTION,
      src: require('@/assets/sensorBlockLinkage.svg'),
      scale: 0.04
    })
  });

  noDamageStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(0,255,0,0.4)'
      }),
      stroke: new Stroke({
        color: '#0f0',
        width: 1.25
      }),
      radius: 5
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  minorCrackStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(0,0,255,0.4)'
      }),
      stroke: new Stroke({
        color: '#00f',
        width: 1.25
      }),
      radius: 5
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  partialDamageStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(255,255,0,0.4)'
      }),
      stroke: new Stroke({
        color: '#ff0',
        width: 1.25
      }),
      radius: 5
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fullDamageStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(255,0,0,0.4)'
      }),
      stroke: new Stroke({
        color: '#f00',
        width: 1.25
      }),
      radius: 5
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  noDamageScaledStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(0,255,0,0.6)'
      }),
      stroke: new Stroke({
        color: '#0f0',
        width: 1.25
      }),
      radius: 8
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  minorCrackScaledStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(0,0,255,0.6)'
      }),
      stroke: new Stroke({
        color: '#00f',
        width: 1.25
      }),
      radius: 8
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  partialDamageScaledStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(255,255,0,0.6)'
      }),
      stroke: new Stroke({
        color: '#ff0',
        width: 1.25
      }),
      radius: 8
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fullDamageScaledStyle = new Style({
    image: new Circle({
      fill: new Fill({
        color: 'rgba(255,0,0,0.6)'
      }),
      stroke: new Stroke({
        color: '#f00',
        width: 1.25
      }),
      radius: 8
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  get blockPolygonLabel() {
    return BlockStore.AddEditBlock.polygonLabel;
  }

  drawBlockHighlightStyle = new Style({
    image: new Circle({
      radius: 10, //width * 2,
      fill: new Fill({
        color: 'rgba(255,255,255,0.2)'
      }),
      stroke: new Stroke({
        color: '#00f',
        width: 2 //width / 2
      })
    }),
    zIndex: Infinity
  });

  get drawBlockLocationStyle() {
    // eslint-disable-next-line
    return (feature: any) => {
      const styles = [
        new Style({
          image: new Circle({
            fill: new Fill({
              color: 'rgba(0,0,0,0)'
            }),
            stroke: new Stroke({
              color: '#f0f',
              width: 1.25
            }),
            radius: 5
          }),
          fill: this.fill,
          stroke: this.stroke
        })
      ];
      if (feature) {
        const coordinate = feature.getGeometry().getCoordinates();
        if (
          coordinate.length > 0 &&
          coordinate[0] &&
          coordinate[0].length > 2
        ) {
          const coordinates = coordinate[0].slice(0, -1);
          // eslint-disable-next-line
          coordinates.forEach((coordinate: any, index: any) => {
            styles.push(
              new Style({
                image: new Circle({
                  radius: 10,
                  fill: new Fill({
                    color: 'rgba(255,100,0,0.3'
                  })
                }),
                text: new Text({
                  text:
                    this.blockPolygonLabel &&
                    this.blockPolygonLabel.length > 0 &&
                    this.blockPolygonLabel[index]
                      ? this.blockPolygonLabel[index].toString()
                      : (index + 1).toString(),
                  font: '12px Calibri, sans-srif',
                  overflow: true,
                  fill: new Fill({
                    color: '#000'
                  }),
                  stroke: new Stroke({
                    color: '#fff',
                    width: 3
                  })
                }),
                geometry: new Point(coordinate)
              })
            );
          });
        }
      }
      return styles;
    };
  }

  drawLocationStyle = new Style({
    image: new Circle({
      fill: new Fill({ color: '#3399CC' }),
      stroke: new Stroke({ color: '#ffffff', width: 2 }),
      radius: 6
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  measureToolStyle = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)'
    }),
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      lineDash: [10, 10],
      width: 2
    }),
    image: new Circle({
      radius: 5,
      stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.7)'
      }),
      fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)'
      })
    })
  });

  measureToolStaticStyle = new Style({
    fill: new Fill({
      color: 'rgba(255, 255, 255, 0.2)'
    }),
    stroke: new Stroke({
      color: '#ffcc33',
      width: 2
    }),
    image: new Circle({
      radius: 7,
      fill: new Fill({
        color: '#ffcc33'
      })
    })
  });

  markLocationStyle = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.FRACTION,
      src: require('@/assets/mark.svg'),
      scale: 1
    })
  });

  currentLocationStyle = new Style({
    image: new Icon({
      anchor: [0.5, 1],
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.FRACTION,
      src: require('@/assets/location.svg'),
      scale: 1
    })
  });

  fruitCountHighlightStyle = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(255,255,255,0.1)'
      }),
      stroke: new Stroke({
        color: '#f0f',
        width: 2 //width / 2
      })
    }),
    zIndex: Infinity
  });

  fieldBinsHighlightStyle = new Style({
    image: new RegularShape({
      fill: new Fill({
        color: 'rgba(255,255,255,0.1)'
      }),
      stroke: new Stroke({
        color: '#f0f',
        width: 2 //width / 2
      }),
      points: 4,
      radius: 10,
      angle: Math.PI / 4
    }),
    zIndex: Infinity
  });

  fruitPhotoStyle = new Style({
    image: new Icon({
      anchor: [0.5, 0.5],
      anchorXUnits: IconAnchorUnits.FRACTION,
      anchorYUnits: IconAnchorUnits.FRACTION,
      src: require('@/assets/photo_camera.svg'),
      scale: 0.04
    })
  });

  fruitCount0Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(0, 0, 183, 0)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 0, 183, 0.3)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL10Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(0, 35, 255, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 35, 255, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL20Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(0, 143, 255, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 143, 255, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL30Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(0, 251, 255, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(0, 251, 255, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL40Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(103, 255, 151, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(103, 255, 151, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL50Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(211, 255, 43, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(211, 255, 43, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL60Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(255, 247, 0, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 247, 0, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL70Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(255, 191, 0, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 191, 0, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL80Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(255, 139, 0, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 139, 0, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL90Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(255, 83, 0, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 83, 0, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountL100Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(255, 31, 0, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(255, 31, 0, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });

  fruitCountG100Style = new Style({
    image: new Circle({
      radius: 3, //width * 2,
      fill: new Fill({
        color: 'rgba(179, 0, 0, 0.6)'
      }),
      stroke: new Stroke({
        color: 'rgba(179, 0, 0, 1)',
        width: 1 //width / 2
      })
    }),
    fill: this.fill,
    stroke: this.stroke
  });
}

export default getModule(MapStyleStore);
