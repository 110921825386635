import store from '@/store';
import {
  Module,
  VuexModule,
  getModule,
  MutationAction,
  Action,
  Mutation
} from 'vuex-module-decorators';
import BlockAPI from '@/store/api/block';
import {
  Block,
  UniqueCrop,
  Crop,
  CropVariety,
  AssignCrop,
  GeomBlock,
  BlocksWithFruitCounts,
  SimpleBlock,
  PaginatedBlocks,
  CropType,
  AssignCorrectionFactor,
  BlocksWithSummary
} from '@/store/models/block';
import { QTablePagination } from '@/store/models/meta';
import helpers from '@/utils/helpers';

interface BlockQuery {
  block?: number;
  farm?: number;
  page?: number;
  pageSize?: number;
  search?: string;
  ordering?: string;
  startDate?: string;
  endDate?: string;
  crop?: number | null;
  cropVariety?: number | null;
  cropSeasonCalendarsSeasons?: number | null;
  seasonStartDate?: string;
  seasonEndDate?: string;
}
function getBlockQuery(params?: BlockQuery) {
  let query = '';
  if (params) {
    query += '?';
    if (params.block) {
      query += `&id=${params.block}`;
    }
    if (params.farm) {
      query += `&farm=${params.farm}`;
    }
    if (params.cropVariety) {
      query += `&crop__variety=${params.cropVariety}`;
    }
    if (params.crop) {
      query += `&crop=${params.crop}`;
    }
    if (params.page) {
      query += `&page=${params.page}`;
    }
    if (params.pageSize) {
      query += `&page_size=${params.pageSize}`;
    }
    if (params.search) {
      query += `&search=${params.search}`;
    }
    if (params.ordering) {
      query += `&ordering=${params.ordering}`;
    }
    if (params.startDate) {
      query += `&start_date=${params.startDate}`;
    }
    if (params.endDate) {
      query += `&end_date=${params.endDate}`;
    }
    if (params.cropSeasonCalendarsSeasons) {
      query += `&crop__season_calendars__seasons=${params.cropSeasonCalendarsSeasons}`;
    }
    if (params.seasonStartDate) {
      query += `&season_start_date=${params.seasonStartDate}`;
    }
    if (params.seasonEndDate) {
      query += `&season_end_date=${params.seasonEndDate}`;
    }
  }
  return query;
}

@Module({ dynamic: true, store, name: 'BlockStore' })
class BlockStore extends VuexModule {
  SimpleBlocks: SimpleBlock[] = [] as SimpleBlock[];
  SeasonWiseSimpleBlocks: SimpleBlock[] = [] as SimpleBlock[];
  Blocks: PaginatedBlocks = {} as PaginatedBlocks;
  BlocksWithFruitCount: BlocksWithFruitCounts[] = [] as BlocksWithFruitCounts[];
  BlocksWithAllFruitCount: BlocksWithFruitCounts[] = [] as BlocksWithFruitCounts[];
  GeomBlocks: GeomBlock[] = [] as GeomBlock[];
  emptyBlock: GeomBlock = {} as GeomBlock;
  AddEditBlock: GeomBlock = {} as GeomBlock;
  BlocksWithSummary: BlocksWithSummary[] = [] as BlocksWithSummary[];

  BlockTablePagination: QTablePagination = {
    sortBy: 'id',
    descending: true,
    page: 1,
    rowsPerPage: 10,
    rowsNumber: 1
  };

  UniqueCrops: UniqueCrop[] = [] as UniqueCrop[];
  Crops: Crop[] = [] as Crop[];
  FarmCrops: Crop[] = [] as Crop[];
  emptyCrop: Crop = {} as Crop;
  AddEditCrop: Crop = {} as Crop;

  AddEditCropType: CropType = {} as CropType;
  emptyCropType: CropType = {} as CropType;

  AddEditCropVariety: CropVariety = {} as CropVariety;
  emptyCropVariety: CropVariety = {} as CropVariety;

  CropTypes: CropType[] = [] as CropType[];
  CropVarieties: CropVariety[] = [] as CropVariety[];
  SeasonCrops: Crop[] = [] as Crop[];
  SeasonCropVarieties: CropVariety[] = [] as CropVariety[];

  ImportBlockDialogVisible = false;
  AssignCorrectionFactorDialog = false;
  PackHouseBatchUploadDialog = false;

  LoadingGeomBlock = false;

  @MutationAction
  async setImportBlockDialogVisible(visible: boolean) {
    return { ImportBlockDialogVisible: visible };
  }

  @MutationAction
  async setAssignCorrectionFactorDialog(visible: boolean) {
    return { AssignCorrectionFactorDialog: visible };
  }

  @MutationAction
  async setPackHouseBatchUploadDialog(visible: boolean) {
    return { PackHouseBatchUploadDialog: visible };
  }

  @MutationAction
  async setBlockTablePagination(data: QTablePagination) {
    return { BlockTablePagination: data };
  }

  @MutationAction
  async setAddEditBlock(block: Block) {
    return { AddEditBlock: block };
  }

  @Mutation
  // @MutationAction
  setMLoadingGeomBlock(value: boolean) {
    this.LoadingGeomBlock = value;
    // return { LoadingGeomBlock: value };
  }

  @MutationAction
  async setLoadingGeomBlock(value: boolean) {
    return { LoadingGeomBlock: value };
  }

  @MutationAction
  async getSimpleBlocks(params?: BlockQuery) {
    const query = getBlockQuery(params);
    const blocks = await BlockAPI.getSimpleBlocks(query);
    return { SimpleBlocks: blocks };
  }

  @MutationAction
  async getSeasonWiseSimpleBlocks(params?: BlockQuery) {
    const query = getBlockQuery(params);
    const blocks = await BlockAPI.getSimpleBlocks(query);
    return { SeasonWiseSimpleBlocks: blocks };
  }

  @MutationAction
  async getAllMyBlocks(params?: BlockQuery) {
    const query = getBlockQuery(params);
    const blocks = await BlockAPI.getAllMyBlocks(query);
    return { Blocks: blocks };
  }

  get loadingGeomBlock() {
    return this.LoadingGeomBlock;
  }

  @MutationAction
  async getAllMyBlocksWithFruitCounts(params?: BlockQuery) {
    const query = getBlockQuery(params);
    const blocks = await BlockAPI.getAllMyBlocksWithFruitCounts(query);
    return {
      BlocksWithFruitCount: blocks
    };
  }

  @MutationAction
  async getAllMyBlocksWithOnlyFruitCounts(params?: BlockQuery) {
    const query = getBlockQuery(params);
    const blocks = await BlockAPI.getAllMyBlocksWithOnlyFruitCounts(query);
    return {
      BlocksWithAllFruitCount: blocks
    };
  }

  @MutationAction
  async getAllMyBlocksWithAvgDM(params?: BlockQuery) {
    const query = getBlockQuery(params);
    const blocks = await BlockAPI.getAllMyBlocksWithAvgDM(query);
    return {
      BlocksWithAllFruitCount: blocks
    };
  }

  @MutationAction
  async getAllMyBlocksWithFloweringCount(params?: BlockQuery) {
    const query = getBlockQuery(params);
    const blocks = await BlockAPI.getAllMyBlocksWithFloweringCount(query);
    return {
      BlocksWithAllFruitCount: blocks
    };
  }

  @MutationAction
  async getAllMyBlocksWithFruitSize(params?: BlockQuery) {
    const query = getBlockQuery(params);
    const blocks = await BlockAPI.getAllMyBlocksWithFruitSize(query);
    return {
      BlocksWithAllFruitCount: blocks
    };
  }

  @MutationAction
  async getAllMyBlocksSummary(params?: BlockQuery) {
    const query = getBlockQuery(params);
    const blocks = await BlockAPI.getAllMyBlocksSummary(query);
    return {
      BlocksWithSummary: blocks
    };
  }

  @MutationAction
  async getAllMyBlocksWithGeom(params?: BlockQuery) {
    const blockStore = getModule(BlockStore);
    let loading = blockStore.LoadingGeomBlock;
    if (!loading) {
      blockStore.setLoadingGeomBlock(true);
    }
    while (loading) {
      await helpers.sleep(100);
      loading = blockStore.LoadingGeomBlock;
    }
    let blocks = blockStore.GeomBlocks;
    if (blocks.length >= 0) {
      const query = getBlockQuery(params);
      blocks = await BlockAPI.getAllMyBlocksWithGeom(query);
    }
    return { GeomBlocks: blocks, LoadingGeomBlock: false };
  }

  @MutationAction
  async clearAllMyBlocksWithGeom() {
    return { GeomBlocks: [] as GeomBlock[] };
  }

  @MutationAction
  async getBlockById(id: number) {
    const block = await BlockAPI.getBlockById(id);
    return { AddEditBlock: block };
  }
  @Action
  async getMapFeatureBlockById(params: {
    id: number;
    startDate: string;
    endDate: string;
    featureType?: string;
  }) {
    let query = `?start_date=${params.startDate}&end_date=${params.endDate}`;
    if (params.featureType) {
      query += `&feature_type=${params.featureType}`;
    }
    return await BlockAPI.getMapFeatureBlockById(params.id, query);
  }
  @Action
  async importBlocks(params: { data: FormData }) {
    const response = await BlockAPI.importBlocks(params.data);
    return response;
  }

  @Action
  async exportFarmBlocks(farm: number) {
    const response = await BlockAPI.exportFarmBlocks(farm);
    return response;
  }

  @Action
  async exportBlock(id: number) {
    const response = await BlockAPI.exportBlock(id);
    return response;
  }

  @Action
  async exportBlocks(params: { farm: number; id?: number }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farm) {
        query += `&block__farm=${params.farm}`;
      }
      if (params.id) {
        query += `&id=${params.id}`;
      }
    }
    const response = (await BlockAPI.exportBlocks(query)) as string[][];
    return response;
  }

  @MutationAction
  async createBlock(block: Block) {
    const addedBlock = await BlockAPI.createBlock(block);
    const blocks = this.Blocks;
    if (blocks.results) {
      blocks.results.push(addedBlock);
    }
    return { Blocks: blocks, AddEditBlock: addedBlock };
  }

  @MutationAction
  async updateBlock(block: Block) {
    const updatedBlock = await BlockAPI.updateBlock(block);

    const blocks = this.Blocks;
    if (blocks.results) {
      const blockIndex = blocks.results.findIndex(x => x.id == block.id);
      if (blockIndex != -1) {
        blocks.results.splice(blockIndex, 1, updatedBlock);
      }
    }
    return {
      Blocks: blocks,
      AddEditBlock: updatedBlock
    };
  }

  @MutationAction
  async deleteBlock(id: number) {
    await BlockAPI.deleteBlock(id);

    const emptyBlock = this.emptyBlock;

    const blocks = this.Blocks;
    if (blocks) {
      const blockIndex = blocks.results.findIndex(x => x.id == id);
      if (blockIndex != -1) {
        blocks.results.splice(blockIndex, 1);
      }
    }

    return {
      Blocks: blocks,
      AddEditBlock: JSON.parse(JSON.stringify(emptyBlock))
    };
  }

  @MutationAction
  async getAllCropTypes() {
    const cropTypes = await BlockAPI.getAllCropTypes();
    return { CropTypes: cropTypes };
  }

  @MutationAction
  async getAllCropVarieties() {
    const cropVarieties = await BlockAPI.getAllCropVarieties();
    return { CropVarieties: cropVarieties };
  }

  @MutationAction
  async setAddEditCrop(crop: Crop) {
    return { AddEditCrop: crop };
  }

  @MutationAction
  async setAddEditCropType(cropType: CropType) {
    return { AddEditCropType: cropType };
  }
  @MutationAction
  async setAddEditCropVariety(cropVariety: CropVariety) {
    return { AddEditCropVariety: cropVariety };
  }
  @MutationAction
  async getAllMyUniqueCrops() {
    const crops = await BlockAPI.getAllMyUniqueCrops();
    return { UniqueCrops: crops };
  }

  @MutationAction
  async getAllCrops() {
    const crops = await BlockAPI.getAllCrops();
    return { Crops: crops };
  }

  @MutationAction
  async getCropById(id: number) {
    const crop = await BlockAPI.getCrop(id);
    return { AddEditCrop: crop };
  }

  @MutationAction
  async getFarmCrops(params: { farmId: number }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farmId) {
        query += `&of_farm=${params.farmId}`;
      }
    }
    const crops = await BlockAPI.getAllCrops(query);
    return { FarmCrops: crops };
  }

  @MutationAction
  async getFarmCropVarieties(params: { farmId: number }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farmId) {
        query += `&of_farm=${params.farmId}`;
      }
    }
    const cropVarieties = await BlockAPI.getAllCropVarieties(query);
    return { SeasonCropVarieties: cropVarieties };
  }

  @MutationAction
  async getFarmAllCrops(params: { farmId: number }) {
    let query = '';
    if (params) {
      query += '?';
      if (params.farmId) {
        query += `&of_farm=${params.farmId}`;
      }
    }
    const crops = await BlockAPI.getAllCrops(query);
    return { SeasonCrops: crops };
  }
  @MutationAction
  async createCrop(crop: Crop) {
    const addedCrop = await BlockAPI.createCrop(crop);
    const crops = this.Crops;
    crops.push(addedCrop);

    return { Crops: crops, AddEditCrop: addedCrop };
  }

  @MutationAction
  async updateCrop(block: Crop) {
    const updatedCrop = await BlockAPI.updateCrop(block);

    const crops = this.Crops;
    const cropIndex = crops.findIndex(x => x.id == block.id);
    if (cropIndex != -1) {
      crops.splice(cropIndex, 1, updatedCrop);
    }
    return {
      Crops: crops,
      AddEditCrop: updatedCrop
    };
  }

  @MutationAction
  async deleteCrop(id: number) {
    await BlockAPI.deleteCrop(id);

    const emptyCrop = this.emptyCrop;

    const crops = this.Crops;
    if (crops) {
      const cropIndex = crops.findIndex(x => x.id == id);
      if (cropIndex != -1) {
        crops.splice(cropIndex, 1);
      }
    }

    return {
      Crops: crops,
      AddEditCrop: JSON.parse(JSON.stringify(emptyCrop))
    };
  }

  @Action
  async assignCrop(assignCrop: AssignCrop) {
    return await BlockAPI.assignCrop(assignCrop);
  }

  @Action
  async assignCorrectionFactor(assignCorrectionFactor: AssignCorrectionFactor) {
    return await BlockAPI.assignCorrectionFactor(assignCorrectionFactor);
  }

  @Action
  async batchUploadPackHouse(params: { data: FormData }) {
    const response = await BlockAPI.batchUploadPackHouse(params.data);
    return response;
  }

  @MutationAction
  async getCropTypeById(id: number) {
    const cropType = await BlockAPI.getCropType(id);
    return { AddEditCropType: cropType };
  }

  @MutationAction
  async createCropType(cropType: CropType) {
    const addedCropType = await BlockAPI.createCropType(cropType);
    const cropTypes = this.CropTypes;
    cropTypes.push(addedCropType);

    return { CropTypes: cropTypes, AddEditCropType: addedCropType };
  }

  @MutationAction
  async updateCropType(cropType: CropType) {
    const updatedCropType = await BlockAPI.updateCropType(cropType);

    const cropTypes = this.CropTypes;
    const cropTypeIndex = cropTypes.findIndex(x => x.id == cropType.id);
    if (cropTypeIndex != -1) {
      cropTypes.splice(cropTypeIndex, 1, updatedCropType);
    }
    return {
      CropTypes: cropTypes,
      AddEditCropType: updatedCropType
    };
  }

  @MutationAction
  async deleteCropType(id: number) {
    await BlockAPI.deleteCropType(id);

    const emptyCropType = this.emptyCropType;

    const cropTypes = this.CropTypes;
    if (cropTypes) {
      const cropTypeIndex = cropTypes.findIndex(x => x.id == id);
      if (cropTypeIndex != -1) {
        cropTypes.splice(cropTypeIndex, 1);
      }
    }

    return {
      CropTypes: cropTypes,
      AddEditCropType: JSON.parse(JSON.stringify(emptyCropType))
    };
  }

  @MutationAction
  async getCropVarietyById(id: number) {
    const cropVariety = await BlockAPI.getCropVariety(id);
    return { AddEditCropVariety: cropVariety };
  }

  @MutationAction
  async createCropVariety(cropVariety: CropVariety) {
    const addedCropVariety = await BlockAPI.createCropVariety(cropVariety);
    const cropVarieties = this.CropVarieties;
    cropVarieties.push(addedCropVariety);

    return {
      CropVarieties: cropVarieties,
      AddEditCropVariety: addedCropVariety
    };
  }

  @MutationAction
  async updateCropVariety(cropVariety: CropVariety) {
    const updatedCropVariety = await BlockAPI.updateCropVariety(cropVariety);

    const cropVarieties = this.CropVarieties;
    const cropVarietyIndex = cropVarieties.findIndex(
      x => x.id == cropVariety.id
    );
    if (cropVarietyIndex != -1) {
      cropVarieties.splice(cropVarietyIndex, 1, updatedCropVariety);
    }
    return {
      CropVarieties: cropVarieties,
      AddEditCropVariety: updatedCropVariety
    };
  }

  @MutationAction
  async deleteCropVariety(id: number) {
    await BlockAPI.deleteCropVariety(id);

    const emptyCropVariety = this.emptyCropVariety;

    const cropVarieties = this.CropVarieties;
    if (cropVarieties) {
      const cropVarietyIndex = cropVarieties.findIndex(x => x.id == id);
      if (cropVarietyIndex != -1) {
        cropVarieties.splice(cropVarietyIndex, 1);
      }
    }

    return {
      CropVarieties: cropVarieties,
      AddEditCropVariety: JSON.parse(JSON.stringify(emptyCropVariety))
    };
  }
}

export default getModule(BlockStore);
